.Project_22-Project-Page-Cont {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.Project_22-Project-Page-Cont .hero-section {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Project_22-Project-Page-Cont .hero-section img {
  width: 32vw;
}

.Project_22-Project-Page-Cont .hero-section .icons {
  position: absolute;
  width: 10vw;
  z-index: 999;
  display: flex;
  right: 0;
  top: 3vw;
}

.Project_22-Project-Page-Cont .hero-section .icons a {
  width: 2vw;
  margin: 0 0.6vw;
}

.Project_22-Project-Page-Cont .hero-section .icons a svg {
  width: 100%;
}

.Project_22-Project-Page-Cont .hero-section .hero-video {
  width: 75vw !important;
  height: 42.2vw !important;
}

.Project_22-Project-Page-Cont .images-cont {
  width: 100%;
  height: auto;
  margin-top: 8vw;
}

.Project_22-Project-Page-Cont .images-cont .image-row {
  width: 100%;
  height: 100vh;
  display: flex;
}

.Project_22-Project-Page-Cont .images-cont .image-row .image {
  height: 100%;
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Project_22-Project-Page-Cont .images-cont .image-row .image img {
  width: 35vw;
}

.Project_22-Project-Page-Cont .images-cont .image-row .no-image {
  height: 100%;
  width: 50%;
}

.Project_22-Project-Page-Cont .credits-cont {
  width: 90%;
  height: 80vh;
  margin-top: 5vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-top: 1px solid gray;
}

.Project_22-Project-Page-Cont .credits-cont .credits-title {
  width: 100%;
  height: auto;
  position: relative;
  display: flex;
  justify-content: center;
}

.Project_22-Project-Page-Cont .credits-cont .credits-title span {
  font-family: "Cormorant Garamond", sans-serif;
  font-size: 2vw;
  text-transform: uppercase;
  letter-spacing: 1vw;
  margin-top: 3vw;
}

.Project_22-Project-Page-Cont .credits-cont p {
  font-family: "Cormorant Garamond", sans-serif;
  font-size: 1.7vw;
  text-transform: uppercase;
  letter-spacing: 0.1vw;
  margin-top: 3vw;
  text-align: center;
  width: 70%;
}

@media (max-width: 1366px) {
  .Project_22-Project-Page-Cont .hero-section img {
    width: 60vh;
  }

  .Project_22-Project-Page-Cont .credits-cont .credits-title span{
      font-size: 5vh;
  }

  .Project_22-Project-Page-Cont .credits-cont p{
     font-size: 3vh; 
  }

  .Project_22-Project-Page-Cont .hero-section .icons a{
      width: 5vh;
      margin: 0 1.2vh;
  }
}

@media (max-width: 770px){
  .Project_22-Project-Page-Cont .hero-section .icons {
      width: 16vh;
      
  }
}
@media (max-width: 500px){
  .Project_22-Project-Page-Cont .hero-section img {
    width: 40vh;
  }
  .Project_22-Project-Page-Cont .hero-section{
      height: 80vh;
  }
  .Project_22-Project-Page-Cont .credits-cont p{
     width: 100%;
  }
}


