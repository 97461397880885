@import url("https://fonts.googleapis.com/css2?family=Cormorant+Garamond:wght@300;400;600&display=swap");

.Card {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  color: white;

  cursor: pointer;
  transition: all 0.3s ease 0s;
}

.Card:hover {
  transform: scale(0.995) !important;
}

.large {
  height: 80%;
  width: 30vw;
}

.medium {
  height: 60%;
  width: 20vw;
}

.small {
  height: 50%;
  width: 17vw;
}
.project-link {
  width: 100%;
  height: 90%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.project-link img {
  width: 100%;
  height: 100%;
  user-select: none;
  position: absolute;
  object-fit: cover;
}

.card-title {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  position: relative;
}

.card-title h2 {
  color: black;
  font-family: "Cormorant Garamond", sans-serif;
  font-weight: 300;
  font-size: 1.6vw;
  user-select: none;
}

.card-title:after {
  content: "";
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 1px;
  bottom: 0;
  left: 0;
  background-color: gray;
  transform-origin: bottom right;
  transition: transform 0.4s ease-out;
}

.Card:hover .card-title:after {
  transform: scaleX(1);
  transform-origin: bottom left;
}

.card-title #drapeau_21 {
  margin-left: -3.5vw;
}
.card-title #drapeau_33 {
  margin-left: -3.5vw;
}
.card-title #drapeau_63 {
  margin-left: 3vw;
}
.card-title #drapeau_62 {
  margin-left: -3vw;
}

@media (max-width: 600px) {
  .large {
    width: 30vh;
    height: 50vh;
  }

  .card-title h2 {
    font-size: 2vh;
  }
  .medium {
    height: 50vh;
    width: 30vh;
  }
  .small {
    height: 50vh;
    width: 30vh;
  }
  .Card{
    margin: 2vh 0;
  }
}
