@import url(https://fonts.googleapis.com/css2?family=Cormorant+Garamond:wght@300;400;600&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  overflow: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.App {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #fefbf3;
  overflow: hidden;
}

.box {
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  position: relative;
  
}

.scroll-arrow {
  z-index: 999;
  position: absolute;
  bottom: 2vw;
  height: auto;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.scroll-arrow svg {
  fill: black;
  width: 2.5vw;
  -webkit-animation: scroll-anim 2s infinite;
          animation: scroll-anim 2s infinite;
}

.scroll-arrow h4 {
  font-family: "Cormorant Garamond", sans-serif;
  font-weight: 300;
  font-size: 1.4vw;
  margin: 0;
  margin-left: 0.5vw;
  margin-bottom: 0.5vw;
  text-align: center;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.not-scroll-arrow {
  display: none;
}

@-webkit-keyframes scroll-anim {
  0% {
    -webkit-transform: translate(0);
            transform: translate(0);
  }
  50% {
    -webkit-transform: translate(0, 1vw);
            transform: translate(0, 1vw);
  }
  100% {
    -webkit-transform: translate(0);
            transform: translate(0);
  }
}

@keyframes scroll-anim {
  0% {
    -webkit-transform: translate(0);
            transform: translate(0);
  }
  50% {
    -webkit-transform: translate(0, 1vw);
            transform: translate(0, 1vw);
  }
  100% {
    -webkit-transform: translate(0);
            transform: translate(0);
  }
}

.Cont {
  display: flex;
  justify-content: center;
  position: relative;
}

.name {
  position: fixed;
  top: 2vw;
  z-index: 999;
  color: transparent;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #3a3a3a;
}

.name h1 {
  font-family: "Cormorant Garamond", sans-serif;
  font-weight: 400;
  font-size: 3vw;

  text-transform: uppercase;
  margin: 0;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
  letter-spacing: 5px;
}

.icons {
  position: absolute;
  width: 10vw;
  z-index: 999;
  display: flex;
  right: 0;
  top: 3vw;
}

.icons a {
  width: 2vw;
  margin: 0 .6vw;
}

.icons a svg {
  width: 100%;
}

.name .art {
  font-family: "Cormorant Garamond", sans-serif;
  font-weight: 300;
  font-size: 1.3vw;
  color: #3a3a3a;
  text-transform: uppercase;
  margin: 0;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
  letter-spacing: 7px;
}

@media (max-width : 600px) {
  .box{
    flex-direction: column;
    margin-top: 15vh;
    height: auto;
  }
  .name h1{
    font-size: 5vh;
  }
  .name .art{
    font-size: 2.2vh;
  }
  .icons{
    width: 100%;
    top: 4vh;
    display: flex;
    justify-content: space-between;
  }
  .icons a{
    width: 4vh;
    margin: 0 2vh;
  }

  .scroll-arrow{
    display: none;
  }
}

.Card {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  color: white;

  cursor: pointer;
  transition: all 0.3s ease 0s;
}

.Card:hover {
  -webkit-transform: scale(0.995) !important;
          transform: scale(0.995) !important;
}

.large {
  height: 80%;
  width: 30vw;
}

.medium {
  height: 60%;
  width: 20vw;
}

.small {
  height: 50%;
  width: 17vw;
}
.project-link {
  width: 100%;
  height: 90%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.project-link img {
  width: 100%;
  height: 100%;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
  position: absolute;
  object-fit: cover;
}

.card-title {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  position: relative;
}

.card-title h2 {
  color: black;
  font-family: "Cormorant Garamond", sans-serif;
  font-weight: 300;
  font-size: 1.6vw;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.card-title:after {
  content: "";
  position: absolute;
  width: 100%;
  -webkit-transform: scaleX(0);
          transform: scaleX(0);
  height: 1px;
  bottom: 0;
  left: 0;
  background-color: gray;
  -webkit-transform-origin: bottom right;
          transform-origin: bottom right;
  transition: -webkit-transform 0.4s ease-out;
  transition: transform 0.4s ease-out;
  transition: transform 0.4s ease-out, -webkit-transform 0.4s ease-out;
}

.Card:hover .card-title:after {
  -webkit-transform: scaleX(1);
          transform: scaleX(1);
  -webkit-transform-origin: bottom left;
          transform-origin: bottom left;
}

.card-title #drapeau_21 {
  margin-left: -3.5vw;
}
.card-title #drapeau_33 {
  margin-left: -3.5vw;
}
.card-title #drapeau_63 {
  margin-left: 3vw;
}
.card-title #drapeau_62 {
  margin-left: -3vw;
}

@media (max-width: 600px) {
  .large {
    width: 30vh;
    height: 50vh;
  }

  .card-title h2 {
    font-size: 2vh;
  }
  .medium {
    height: 50vh;
    width: 30vh;
  }
  .small {
    height: 50vh;
    width: 30vh;
  }
  .Card{
    margin: 2vh 0;
  }
}

.Project_11-Project-Page-Cont {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-x: hidden;
}

.Project_11-Project-Page-Cont .hero-section {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Project_11-Project-Page-Cont .hero-section .icons {
  position: absolute;
  width: 10vw;
  z-index: 999;
  display: flex;
  right: 0;
  top: 3vw;
}

.Project_11-Project-Page-Cont .hero-section .icons a {
  width: 2vw;
  margin: 0 0.6vw;
}

.Project_11-Project-Page-Cont .hero-section .icons a svg {
  width: 100%;
}

.Project_11-Project-Page-Cont .hero-section .hero-video {
  width: 75vw !important;
  height: 42.2vw !important;
}

.Project_11-Project-Page-Cont .images-cont {
  width: 100%;
  height: auto;
  margin-top: 8vw;
}

.Project_11-Project-Page-Cont .images-cont .image-row {
  width: 100%;
  height: 100vh;
  display: flex;
}

.Project_11-Project-Page-Cont .images-cont .image-row .image {
  height: 100%;
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Project_11-Project-Page-Cont .images-cont .image-row .image img {
  width: 35vw;
}

.Project_11-Project-Page-Cont .images-cont .image-row .no-image {
  height: 100%;
  width: 50%;
}

.Project_11-Project-Page-Cont .credits-cont {
  width: 90%;
  height: 80vh;
  margin-top: 5vw;
  display: flex;
  border-top: 1px solid gray;
}

.Project_11-Project-Page-Cont .credits-cont .cridits-title {
  height: 100%;
  width: 50%;
  position: relative;
}

.Project_11-Project-Page-Cont .credits-cont .cridits-title span {
  font-family: "Cormorant Garamond", sans-serif;
  font-size: 2vw;
  text-transform: uppercase;
  letter-spacing: 1vw;
  position: absolute;
  top: 3vw;
}
.Project_11-Project-Page-Cont .credits-cont .description {
  height: 100%;
  width: 50%;
  padding-top: 1vw;
  display: flex;
  flex-direction: column;
}
.Project_11-Project-Page-Cont .credits-cont .description .description-row {
  width: 100%;
  height: 6vw;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid gray;
}

.Project_11-Project-Page-Cont .credits-cont .description .description-row span {
  font-family: "Cormorant Garamond", sans-serif;
  font-size: 1.5vw;
  text-transform: uppercase;
  letter-spacing: 3px;
}

@media (max-width: 1366px) {
  .Project_11-Project-Page-Cont .hero-section .hero-video {
    width: 127.5vh !important;
    height: 71.74vh !important;
  }
  .Project_11-Project-Page-Cont .images-cont .image-row .no-image {
    display: none;
  }
  .Project_11-Project-Page-Cont .images-cont .image-row .image {
    width: 100%;
  }
  .Project_11-Project-Page-Cont .images-cont .image-row .image img {
    width: 65vh;
  }
  .Project_11-Project-Page-Cont
    .credits-cont
    .description
    .description-row
    span {
    font-size: 2vh;
  }
}

@media (max-width: 900px) {
  .Project_11-Project-Page-Cont .credits-cont {
    flex-direction: column;
    align-items: center;
  }
  .Project_11-Project-Page-Cont .credits-cont .cridits-title {
    height: auto;

    display: flex;
    justify-content: center;
    width: 100%;
  }
  .Project_11-Project-Page-Cont .credits-cont .description {
    height: 80%;
    margin-top: 10vh;
    width: 100%;
  }
  .Project_11-Project-Page-Cont
    .credits-cont
    .description
    .description-row
    span {
    font-size: 3vh;
  }
  .Project_11-Project-Page-Cont .credits-cont .cridits-title span {
    position: relative;
    font-size: 5vh;
  }
  .Project_11-Project-Page-Cont .hero-section .icons a {
    width: 6vh;
    margin: 0 1vh;
  }
}

@media (max-width: 770px) {
  .Project_11-Project-Page-Cont .hero-section .icons {
    width: 16vh;
  }
}
@media (max-width: 600px) {
  .Project_11-Project-Page-Cont .hero-section .icons {
    width: 15vh;
    top: 6vh;
    right: 3vh;
  }
  .Project_11-Project-Page-Cont
    .credits-cont
    .description
    .description-row
    span {
    font-size: 2vh;
  }
  .Project_11-Project-Page-Cont
    .credits-cont
    .description
    .description-row{
      height: 5vh;
    }
    .Project_11-Project-Page-Cont
    .credits-cont{
      width: 100%;
    }
}
@media (max-width: 420px) {
  .Project_11-Project-Page-Cont {
    width: 110%;
  }
  .Project_11-Project-Page-Cont
    .credits-cont
    .description
    .description-row
    span {
    font-size: 2vh;
  }
  .Project_11-Project-Page-Cont
    .credits-cont
    .description
    .description-row{
      height: 5vh;
    }
}

.Project_12-Project-Page-Cont {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-x: hidden;
}

.Project_12-Project-Page-Cont .hero-section {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Project_12-Project-Page-Cont .hero-section .icons {
  position: absolute;
  width: 10vw;
  z-index: 999;
  display: flex;
  right: 0;
  top: 3vw;
}

.Project_12-Project-Page-Cont .hero-section .icons a {
  width: 2vw;
  margin: 0 .6vw;
}

.Project_12-Project-Page-Cont .hero-section .icons a svg {
  width: 100%;
}

.Project_12-Project-Page-Cont .hero-section .hero-video {
  width: 75vw !important;
  height: 42.2vw !important;
}

.Project_12-Project-Page-Cont .images-cont {
  width: 100%;
  height: auto;
  margin-top: 8vw;
}

.Project_12-Project-Page-Cont .images-cont .image-row {
  width: 100%;
  height: 100vh;
  display: flex;
}

.Project_12-Project-Page-Cont .images-cont .image-row .image {
  height: 100%;
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Project_12-Project-Page-Cont .images-cont .image-row .image img {
  width: 35vw;
}

.Project_12-Project-Page-Cont .images-cont .image-row .no-image {
  height: 100%;
  width: 50%;
}

.Project_12-Project-Page-Cont .credits-cont {
  width: 90%;
  height: 80vh;
  margin-top: 5vw;
  display: flex;
  border-top: 1px solid gray;
}

.Project_12-Project-Page-Cont .credits-cont .cridits-title {
  height: 100%;
  width: 50%;
  position: relative;
}

.Project_12-Project-Page-Cont .credits-cont .cridits-title span {
  font-family: "Cormorant Garamond", sans-serif;
  font-size: 2vw;
  text-transform: uppercase;
  letter-spacing: 1vw;
  position: absolute;
  top: 3vw;
}
.Project_12-Project-Page-Cont .credits-cont .description {
  height: 100%;
  width: 50%;
  padding-top: 1vw;
  display: flex;
  flex-direction: column;
}
.Project_12-Project-Page-Cont .credits-cont .description .description-row {
  width: 100%;
  height: 6vw;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid gray;
}

.Project_12-Project-Page-Cont .credits-cont .description .description-row span{
  font-family: "Cormorant Garamond", sans-serif;
  font-size: 1.5vw;
  text-transform: uppercase;
  letter-spacing: 3px;
}

.Project_12-Project-Page-Cont .credits-cont .description .description-row #project-name{
    font-size: 1.3vw;
}

@media (max-width: 1366px) {
  .Project_12-Project-Page-Cont .hero-section .hero-video {
    width: 127.5vh !important;
    height: 71.74vh !important;
  }
  .Project_12-Project-Page-Cont .images-cont .image-row .no-image {
    display: none;
  }
  .Project_12-Project-Page-Cont .images-cont .image-row .image {
    width: 100%;
  }
  .Project_12-Project-Page-Cont .images-cont .image-row .image img {
    width: 65vh;
  }
  .Project_12-Project-Page-Cont
    .credits-cont
    .description
    .description-row
    span {
    font-size: 2vh;
  }
}

@media (max-width: 900px) {
  .Project_12-Project-Page-Cont .credits-cont {
    flex-direction: column;
    align-items: center;
  }
  .Project_12-Project-Page-Cont .credits-cont .cridits-title {
    height: auto;

    display: flex;
    justify-content: center;
    width: 100%;
  }
  .Project_12-Project-Page-Cont .credits-cont .description {
    height: 80%;
    margin-top: 10vh;
    width: 100%;
  }
  .Project_12-Project-Page-Cont
    .credits-cont
    .description
    .description-row
    span {
    font-size: 3vh;
  }
  .Project_12-Project-Page-Cont .credits-cont .cridits-title span {
    position: relative;
    font-size: 5vh;
  }
  .Project_12-Project-Page-Cont .credits-cont .description .description-row #project-name{
    font-size: 2.5vh;
  }
  .Project_12-Project-Page-Cont .hero-section .icons a {
    width: 6vh;
    margin: 0 1vh;
  }
}

@media (max-width: 770px) {
  .Project_12-Project-Page-Cont .hero-section .icons {
    width: 16vh;
  }
  .Project_12-Project-Page-Cont .credits-cont .description .description-row #project-name{
    font-size: 2vh;
  }
}
@media (max-width: 600px) {
  .Project_12-Project-Page-Cont .hero-section .icons {
    width: 15vh;
    top: 6vh;
    right: 3vh;
  }
  .Project_12-Project-Page-Cont
    .credits-cont
    .description
    .description-row
    span {
    font-size: 2vh;
  }
  .Project_12-Project-Page-Cont
    .credits-cont
    .description
    .description-row{
      height: 5vh;
    }
    .Project_12-Project-Page-Cont
    .credits-cont{
      width: 100%;
    }
    .Project_12-Project-Page-Cont .credits-cont .description .description-row #project-name{
    font-size: 2.2vh;
  }
}
@media (max-width: 420px) {
  .Project_12-Project-Page-Cont {
    width: 110%;
  }
  .Project_12-Project-Page-Cont
    .credits-cont
    .description
    .description-row
    span {
    font-size: 2vh;
  }
  .Project_12-Project-Page-Cont
    .credits-cont
    .description
    .description-row{
      height: 5vh;
    }
    .Project_12-Project-Page-Cont .credits-cont .description .description-row #project-name{
    font-size: 1.2vh;
  }
}

.Project_13-Project-Page-Cont {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.Project_13-Project-Page-Cont .hero-section {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Project_13-Project-Page-Cont .hero-section .icons {
  position: absolute;
  width: 10vw;
  z-index: 999;
  display: flex;
  right: 0;
  top: 3vw;
}

.Project_13-Project-Page-Cont .hero-section .icons a {
  width: 2vw;
  margin: 0 .6vw;
}

.Project_13-Project-Page-Cont .hero-section .icons a svg {
  width: 100%;
}

.Project_13-Project-Page-Cont .hero-section .hero-video {
  width: 75vw !important;
  height: 42.2vw !important;
}

.Project_13-Project-Page-Cont .images-cont {
  width: 100%;
  height: auto;
  margin-top: 8vw;
}

.Project_13-Project-Page-Cont .images-cont .image-row {
  width: 100%;
  height: 100vh;
  display: flex;
}

.Project_13-Project-Page-Cont .images-cont .image-row .image {
  height: 100%;
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Project_13-Project-Page-Cont .images-cont .image-row .image img {
  width: 35vw;
}

.Project_13-Project-Page-Cont .images-cont .image-row .no-image {
  height: 100%;
  width: 50%;
}

.Project_13-Project-Page-Cont .credits-cont {
  width: 90%;
  height: 80vh;
  margin-top: 5vw;
  display: flex;
  border-top: 1px solid gray;
}

.Project_13-Project-Page-Cont .credits-cont .cridits-title {
  height: 100%;
  width: 50%;
  position: relative;
}

.Project_13-Project-Page-Cont .credits-cont .cridits-title span {
  font-family: "Cormorant Garamond", sans-serif;
  font-size: 2vw;
  text-transform: uppercase;
  letter-spacing: 1vw;
  position: absolute;
  top: 3vw;
}
.Project_13-Project-Page-Cont .credits-cont .description {
  height: 100%;
  width: 50%;
  padding-top: 1vw;
  display: flex;
  flex-direction: column;
}
.Project_13-Project-Page-Cont .credits-cont .description .description-row {
  width: 100%;
  height: 6vw;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid gray;
}

.Project_13-Project-Page-Cont .credits-cont .description .description-row span{
  font-family: "Cormorant Garamond", sans-serif;
  font-size: 1.5vw;
  text-transform: uppercase;
  letter-spacing: 3px;
}
@media (max-width: 1366px) {
  .Project_13-Project-Page-Cont .hero-section .hero-video {
    width: 127.5vh !important;
    height: 71.74vh !important;
  }
  .Project_13-Project-Page-Cont .images-cont .image-row .no-image {
    display: none;
  }
  .Project_13-Project-Page-Cont .images-cont .image-row .image {
    width: 100%;
  }
  .Project_13-Project-Page-Cont .images-cont .image-row .image img {
    width: 65vh;
  }
  .Project_13-Project-Page-Cont
    .credits-cont
    .description
    .description-row
    span {
    font-size: 2vh;
  }
}

@media (max-width: 900px) {
  .Project_13-Project-Page-Cont .credits-cont {
    flex-direction: column;
    align-items: center;
  }
  .Project_13-Project-Page-Cont .credits-cont .cridits-title {
    height: auto;

    display: flex;
    justify-content: center;
    width: 100%;
  }
  .Project_13-Project-Page-Cont .credits-cont .description {
    height: 80%;
    margin-top: 10vh;
    width: 100%;
  }
  .Project_13-Project-Page-Cont
    .credits-cont
    .description
    .description-row
    span {
    font-size: 3vh;
  }
  .Project_13-Project-Page-Cont .credits-cont .cridits-title span {
    position: relative;
    font-size: 5vh;
  }
  .Project_13-Project-Page-Cont .hero-section .icons a {
    width: 6vh;
    margin: 0 1vh;
  }
}

@media (max-width: 770px) {
  .Project_13-Project-Page-Cont .hero-section .icons {
    width: 16vh;
  }
}
@media (max-width: 600px) {
  .Project_13-Project-Page-Cont .hero-section .icons {
    width: 15vh;
    top: 6vh;
    right: 3vh;
  }
  .Project_13-Project-Page-Cont
    .credits-cont
    .description
    .description-row
    span {
    font-size: 2vh;
  }
  .Project_13-Project-Page-Cont
    .credits-cont
    .description
    .description-row{
      height: 5vh;
    }
    .Project_13-Project-Page-Cont
    .credits-cont{
      width: 100%;
    }
}
@media (max-width: 420px) {
  .Project_13-Project-Page-Cont {
    width: 110%;
  }
  .Project_13-Project-Page-Cont
    .credits-cont
    .description
    .description-row
    span {
    font-size: 2vh;
  }
  .Project_13-Project-Page-Cont
    .credits-cont
    .description
    .description-row{
      height: 5vh;
    }
}

.Project_21-Project-Page-Cont {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-x: hidden;
}

.Project_21-Project-Page-Cont .hero-section {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Project_21-Project-Page-Cont .hero-section .icons {
  position: absolute;
  width: 10vw;
  z-index: 999;
  display: flex;
  right: 0;
  top: 3vw;
}

.Project_21-Project-Page-Cont .hero-section .icons a {
  width: 2vw;
  margin: 0 .6vw;
}

.Project_21-Project-Page-Cont .hero-section .icons a svg {
  width: 100%;
}

.Project_21-Project-Page-Cont .hero-section .hero-video {
  width: 75vw !important;
  height: 42.2vw !important;
}

.Project_21-Project-Page-Cont .images-cont {
  width: 100%;
  height: auto;
  margin-top: 8vw;
}

.Project_21-Project-Page-Cont .images-cont .image-row {
  width: 100%;
  height: 100vh;
  display: flex;
}

.Project_21-Project-Page-Cont .images-cont .image-row .image {
  height: 100%;
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Project_21-Project-Page-Cont .images-cont .image-row .image img {
  width: 35vw;
}

.Project_21-Project-Page-Cont .images-cont .image-row .no-image {
  height: 100%;
  width: 50%;
}

.Project_21-Project-Page-Cont .credits-cont {
  width: 90%;
  height: 80vh;
  margin-top: 5vw;
  display: flex;
  border-top: 1px solid gray;
}

.Project_21-Project-Page-Cont .credits-cont .cridits-title {
  height: 100%;
  width: 50%;
  position: relative;
}

.Project_21-Project-Page-Cont .credits-cont .cridits-title span {
  font-family: "Cormorant Garamond", sans-serif;
  font-size: 2vw;
  text-transform: uppercase;
  letter-spacing: 1vw;
  position: absolute;
  top: 3vw;
}
.Project_21-Project-Page-Cont .credits-cont .description {
  height: 100%;
  width: 50%;
  padding-top: 1vw;
  display: flex;
  flex-direction: column;
}
.Project_21-Project-Page-Cont .credits-cont .description .description-row {
  width: 100%;
  height: 6vw;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid gray;
}

.Project_21-Project-Page-Cont .credits-cont .description .description-row span{
  font-family: "Cormorant Garamond", sans-serif;
  font-size: 1.5vw;
  text-transform: uppercase;
  letter-spacing: 3px;
}

@media (max-width: 1366px) {
  .Project_21-Project-Page-Cont .hero-section .hero-video {
    width: 127.5vh !important;
    height: 71.74vh !important;
  }
  .Project_21-Project-Page-Cont .images-cont .image-row .no-image {
    display: none;
  }
  .Project_21-Project-Page-Cont .images-cont .image-row .image {
    width: 100%;
  }
  .Project_21-Project-Page-Cont .images-cont .image-row .image img {
    width: 65vh;
  }
  .Project_21-Project-Page-Cont
    .credits-cont
    .description
    .description-row
    span {
    font-size: 2vh;
  }
}

@media (max-width: 900px) {
  .Project_21-Project-Page-Cont .credits-cont {
    flex-direction: column;
    align-items: center;
  }
  .Project_21-Project-Page-Cont .credits-cont .cridits-title {
    height: auto;

    display: flex;
    justify-content: center;
    width: 100%;
  }
  .Project_21-Project-Page-Cont .credits-cont .description {
    height: 80%;
    margin-top: 10vh;
    width: 100%;
  }
  .Project_21-Project-Page-Cont
    .credits-cont
    .description
    .description-row
    span {
    font-size: 3vh;
  }
  .Project_21-Project-Page-Cont .credits-cont .cridits-title span {
    position: relative;
    font-size: 5vh;
  }
  .Project_21-Project-Page-Cont .hero-section .icons a {
    width: 6vh;
    margin: 0 1vh;
  }
}

@media (max-width: 770px) {
  .Project_21-Project-Page-Cont .hero-section .icons {
    width: 16vh;
  }
}
@media (max-width: 600px) {
  .Project_21-Project-Page-Cont .hero-section .icons {
    width: 15vh;
    top: 6vh;
    right: 3vh;
  }
  .Project_21-Project-Page-Cont
    .credits-cont
    .description
    .description-row
    span {
    font-size: 2vh;
  }
  .Project_21-Project-Page-Cont
    .credits-cont
    .description
    .description-row{
      height: 5vh;
    }
    .Project_21-Project-Page-Cont
    .credits-cont{
      width: 100%;
    }
}
@media (max-width: 420px) {
  .Project_21-Project-Page-Cont {
    width: 110%;
  }
  .Project_21-Project-Page-Cont
    .credits-cont
    .description
    .description-row
    span {
    font-size: 2vh;
  }
  .Project_21-Project-Page-Cont
    .credits-cont
    .description
    .description-row{
      height: 5vh;
    }
}
.Project_22-Project-Page-Cont {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.Project_22-Project-Page-Cont .hero-section {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Project_22-Project-Page-Cont .hero-section img {
  width: 32vw;
}

.Project_22-Project-Page-Cont .hero-section .icons {
  position: absolute;
  width: 10vw;
  z-index: 999;
  display: flex;
  right: 0;
  top: 3vw;
}

.Project_22-Project-Page-Cont .hero-section .icons a {
  width: 2vw;
  margin: 0 0.6vw;
}

.Project_22-Project-Page-Cont .hero-section .icons a svg {
  width: 100%;
}

.Project_22-Project-Page-Cont .hero-section .hero-video {
  width: 75vw !important;
  height: 42.2vw !important;
}

.Project_22-Project-Page-Cont .images-cont {
  width: 100%;
  height: auto;
  margin-top: 8vw;
}

.Project_22-Project-Page-Cont .images-cont .image-row {
  width: 100%;
  height: 100vh;
  display: flex;
}

.Project_22-Project-Page-Cont .images-cont .image-row .image {
  height: 100%;
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Project_22-Project-Page-Cont .images-cont .image-row .image img {
  width: 35vw;
}

.Project_22-Project-Page-Cont .images-cont .image-row .no-image {
  height: 100%;
  width: 50%;
}

.Project_22-Project-Page-Cont .credits-cont {
  width: 90%;
  height: 80vh;
  margin-top: 5vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-top: 1px solid gray;
}

.Project_22-Project-Page-Cont .credits-cont .credits-title {
  width: 100%;
  height: auto;
  position: relative;
  display: flex;
  justify-content: center;
}

.Project_22-Project-Page-Cont .credits-cont .credits-title span {
  font-family: "Cormorant Garamond", sans-serif;
  font-size: 2vw;
  text-transform: uppercase;
  letter-spacing: 1vw;
  margin-top: 3vw;
}

.Project_22-Project-Page-Cont .credits-cont p {
  font-family: "Cormorant Garamond", sans-serif;
  font-size: 1.7vw;
  text-transform: uppercase;
  letter-spacing: 0.1vw;
  margin-top: 3vw;
  text-align: center;
  width: 70%;
}

@media (max-width: 1366px) {
  .Project_22-Project-Page-Cont .hero-section img {
    width: 60vh;
  }

  .Project_22-Project-Page-Cont .credits-cont .credits-title span{
      font-size: 5vh;
  }

  .Project_22-Project-Page-Cont .credits-cont p{
     font-size: 3vh; 
  }

  .Project_22-Project-Page-Cont .hero-section .icons a{
      width: 5vh;
      margin: 0 1.2vh;
  }
}

@media (max-width: 770px){
  .Project_22-Project-Page-Cont .hero-section .icons {
      width: 16vh;
      
  }
}
@media (max-width: 500px){
  .Project_22-Project-Page-Cont .hero-section img {
    width: 40vh;
  }
  .Project_22-Project-Page-Cont .hero-section{
      height: 80vh;
  }
  .Project_22-Project-Page-Cont .credits-cont p{
     width: 100%;
  }
}



.Project_23-Project-Page-Cont {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-x: hidden;
}

.Project_23-Project-Page-Cont .hero-section {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Project_23-Project-Page-Cont .hero-section .icons {
  position: absolute;
  width: 10vw;
  z-index: 999;
  display: flex;
  right: 0;
  top: 3vw;
}

.Project_23-Project-Page-Cont .hero-section .icons a {
  width: 2vw;
  margin: 0 .6vw;
}

.Project_23-Project-Page-Cont .hero-section .icons a svg {
  width: 100%;
}

.Project_23-Project-Page-Cont .hero-section .hero-video {
  width: 75vw !important;
  height: 42.2vw !important;
}

.Project_23-Project-Page-Cont .images-cont {
  width: 100%;
  height: auto;
  margin-top: 8vw;
}

.Project_23-Project-Page-Cont .images-cont .image-row {
  width: 100%;
  height: 100vh;
  display: flex;
}

.Project_23-Project-Page-Cont .images-cont .image-row .image {
  height: 100%;
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Project_23-Project-Page-Cont .images-cont .image-row .image img {
  width: 35vw;
}

.Project_23-Project-Page-Cont .images-cont .image-row .image #larger-image{
  width: 45vw;
}

.Project_23-Project-Page-Cont .images-cont .image-row .no-image {
  height: 100%;
  width: 50%;
}

.Project_23-Project-Page-Cont .credits-cont {
  width: 90%;
  height: 80vh;
  margin-top: 5vw;
  display: flex;
  border-top: 1px solid gray;
}

.Project_23-Project-Page-Cont .credits-cont .cridits-title {
  height: 100%;
  width: 50%;
  position: relative;
}

.Project_23-Project-Page-Cont .credits-cont .cridits-title span {
  font-family: "Cormorant Garamond", sans-serif;
  font-size: 2vw;
  text-transform: uppercase;
  letter-spacing: 1vw;
  position: absolute;
  top: 3vw;
}
.Project_23-Project-Page-Cont .credits-cont .description {
  height: 100%;
  width: 50%;
  padding-top: 1vw;
  display: flex;
  flex-direction: column;
}
.Project_23-Project-Page-Cont .credits-cont .description .description-row {
  width: 100%;
  height: 6vw;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid gray;
}

.Project_23-Project-Page-Cont .credits-cont .description .description-row span{
  font-family: "Cormorant Garamond", sans-serif;
  font-size: 1.5vw;
  text-transform: uppercase;
  letter-spacing: 3px;
}

@media (max-width: 1366px) {
  .Project_23-Project-Page-Cont .hero-section .hero-video {
    width: 127.5vh !important;
    height: 71.74vh !important;
  }
  .Project_23-Project-Page-Cont .images-cont .image-row .no-image {
    display: none;
  }
  .Project_23-Project-Page-Cont .images-cont .image-row .image {
    width: 100%;
  }
  .Project_23-Project-Page-Cont .images-cont .image-row .image img {
    width: 65vh;
  }
  .Project_23-Project-Page-Cont
    .credits-cont
    .description
    .description-row
    span {
    font-size: 2vh;
  }
}

@media (max-width: 900px) {
  .Project_23-Project-Page-Cont .credits-cont {
    flex-direction: column;
    align-items: center;
  }
  .Project_23-Project-Page-Cont .credits-cont .cridits-title {
    height: auto;

    display: flex;
    justify-content: center;
    width: 100%;
  }
  .Project_23-Project-Page-Cont .credits-cont .description {
    height: 80%;
    margin-top: 10vh;
    width: 100%;
  }
  .Project_23-Project-Page-Cont
    .credits-cont
    .description
    .description-row
    span {
    font-size: 3vh;
  }
  .Project_23-Project-Page-Cont .credits-cont .cridits-title span {
    position: relative;
    font-size: 5vh;
  }
  .Project_23-Project-Page-Cont .hero-section .icons a {
    width: 6vh;
    margin: 0 1vh;
  }
}

@media (max-width: 770px) {
  .Project_23-Project-Page-Cont .hero-section .icons {
    width: 16vh;
  }
}
@media (max-width: 600px) {
  .Project_23-Project-Page-Cont .hero-section .icons {
    width: 15vh;
    top: 6vh;
    right: 3vh;
  }
  .Project_23-Project-Page-Cont
    .credits-cont
    .description
    .description-row
    span {
    font-size: 2vh;
  }
  .Project_23-Project-Page-Cont
    .credits-cont
    .description
    .description-row{
      height: 5vh;
    }
    .Project_23-Project-Page-Cont
    .credits-cont{
      width: 100%;
    }
    
}
@media (max-width: 420px) {
  .Project_23-Project-Page-Cont {
    width: 110%;
  }
  .Project_23-Project-Page-Cont
    .credits-cont
    .description
    .description-row
    span {
    font-size: 2vh;
  }
  .Project_23-Project-Page-Cont
    .credits-cont
    .description
    .description-row{
      height: 5vh;
    }
    .Project_23-Project-Page-Cont .images-cont .image-row .image{
      justify-content: flex-start;
    }

    .Project_23-Project-Page-Cont .images-cont .image-row .image #larger-image{
      width: 50vh;
    }
    
}

.Project_31-Project-Page-Cont {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-x: hidden;
}

.Project_31-Project-Page-Cont .hero-section {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Project_31-Project-Page-Cont .hero-section .icons {
  position: absolute;
  width: 10vw;
  z-index: 999;
  display: flex;
  right: 0;
  top: 3vw;
}

.Project_31-Project-Page-Cont .hero-section .icons a {
  width: 2vw;
  margin: 0 0.6vw;
}

.Project_31-Project-Page-Cont .hero-section .icons a svg {
  width: 100%;
}

.Project_31-Project-Page-Cont .hero-section .hero-video {
  width: 75vw !important;
  height: 42.2vw !important;
}

.Project_31-Project-Page-Cont .images-cont {
  width: 100%;
  height: auto;
  margin-top: 8vw;
}

.Project_31-Project-Page-Cont .images-cont .image-row {
  width: 100%;
  height: 100vh;
  display: flex;
}

.Project_31-Project-Page-Cont .images-cont .image-row .image {
  height: 100%;
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Project_31-Project-Page-Cont .images-cont .image-row .image img {
  width: 35vw;
}

.Project_31-Project-Page-Cont .images-cont .image-row .no-image {
  height: 100%;
  width: 50%;
}

.Project_31-Project-Page-Cont .credits-cont {
  width: 90%;
  height: 80vh;
  margin-top: 5vw;
  display: flex;
  border-top: 1px solid gray;
}

.Project_31-Project-Page-Cont .credits-cont .cridits-title {
  height: 100%;
  width: 50%;
  position: relative;
}

.Project_31-Project-Page-Cont .credits-cont .cridits-title span {
  font-family: "Cormorant Garamond", sans-serif;
  font-size: 2vw;
  text-transform: uppercase;
  letter-spacing: 1vw;
  position: absolute;
  top: 3vw;
}
.Project_31-Project-Page-Cont .credits-cont .description {
  height: 100%;
  width: 50%;
  padding-top: 1vw;
  display: flex;
  flex-direction: column;
}
.Project_31-Project-Page-Cont .credits-cont .description .description-row {
  width: 100%;
  height: 6vw;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid gray;
}

.Project_31-Project-Page-Cont .credits-cont .description .description-row span {
  font-family: "Cormorant Garamond", sans-serif;
  font-size: 1.5vw;
  text-transform: uppercase;
  letter-spacing: 3px;
}

.Project_31-Project-Page-Cont
  .credits-cont
  .description
  .description-row
  #long-title {
  font-size: 1.1vw;
}

@media (max-width: 1366px) {
  .Project_31-Project-Page-Cont .hero-section .hero-video {
    width: 127.5vh !important;
    height: 71.74vh !important;
  }
  .Project_31-Project-Page-Cont .images-cont .image-row .no-image {
    display: none;
  }
  .Project_31-Project-Page-Cont .images-cont .image-row .image {
    width: 100%;
  }
  .Project_31-Project-Page-Cont .images-cont .image-row .image img {
    width: 65vh;
  }
  .Project_31-Project-Page-Cont
    .credits-cont
    .description
    .description-row
    span {
    font-size: 2vh;
  }
}

@media (max-width: 900px) {
  .Project_31-Project-Page-Cont .credits-cont {
    flex-direction: column;
    align-items: center;
  }
  .Project_31-Project-Page-Cont .credits-cont .cridits-title {
    height: auto;

    display: flex;
    justify-content: center;
    width: 100%;
  }
  .Project_31-Project-Page-Cont .credits-cont .description {
    height: 80%;
    margin-top: 10vh;
    width: 100%;
  }
  .Project_31-Project-Page-Cont
    .credits-cont
    .description
    .description-row
    span {
    font-size: 3vh;
  }
  .Project_31-Project-Page-Cont .credits-cont .cridits-title span {
    position: relative;
    font-size: 5vh;
  }
  .Project_31-Project-Page-Cont .hero-section .icons a {
    width: 6vh;
    margin: 0 1vh;
  }
}

@media (max-width: 770px) {
  .Project_31-Project-Page-Cont .hero-section .icons {
    width: 16vh;
  }
}
@media (max-width: 600px) {
  .Project_31-Project-Page-Cont .hero-section .icons {
    width: 15vh;
    top: 6vh;
    right: 3vh;
  }
  .Project_31-Project-Page-Cont
    .credits-cont
    .description
    .description-row
    span {
    font-size: 2vh;
  }
  .Project_31-Project-Page-Cont
    .credits-cont
    .description
    .description-row{
      height: 5vh;
    }
    .Project_31-Project-Page-Cont
    .credits-cont{
      width: 100%;
    }
}
@media (max-width: 420px) {
  .Project_31-Project-Page-Cont {
    width: 110%;
  }
  .Project_11-Project-Page-Cont
    .credits-cont
    .description
    .description-row
    span {
    font-size: 2vh;
  }
  .Project_31-Project-Page-Cont
    .credits-cont
    .description
    .description-row{
      height: 5vh;
    }
    .Project_31-Project-Page-Cont
  .credits-cont
  .description
  .description-row
  #long-title {
  font-size: 1.2vh;
}
}

.Project_32-Project-Page-Cont {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-x: hidden;
}

.Project_32-Project-Page-Cont .hero-section {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Project_32-Project-Page-Cont .hero-section .icons {
  position: absolute;
  width: 10vw;
  z-index: 999;
  display: flex;
  right: 0;
  top: 3vw;
}

.Project_32-Project-Page-Cont .hero-section .icons a {
  width: 2vw;
  margin: 0 .6vw;
}

.Project_32-Project-Page-Cont .hero-section .icons a svg {
  width: 100%;
}

.Project_32-Project-Page-Cont .hero-section .hero-video {
  width: 75vw !important;
  height: 42.2vw !important;
}

.Project_32-Project-Page-Cont .images-cont {
  width: 100%;
  height: auto;
  margin-top: 8vw;
}

.Project_32-Project-Page-Cont .images-cont .image-row {
  width: 100%;
  height: 100vh;
  display: flex;
}

.Project_32-Project-Page-Cont .images-cont .image-row .image {
  height: 100%;
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Project_32-Project-Page-Cont .images-cont .image-row .image img {
  width: 35vw;
}

.Project_32-Project-Page-Cont .images-cont .image-row .no-image {
  height: 100%;
  width: 50%;
}

.Project_32-Project-Page-Cont .credits-cont {
  width: 90%;
  height: 80vh;
  margin-top: 5vw;
  display: flex;
  border-top: 1px solid gray;
}

.Project_32-Project-Page-Cont .credits-cont .cridits-title {
  height: 100%;
  width: 50%;
  position: relative;
}

.Project_32-Project-Page-Cont .credits-cont .cridits-title span {
  font-family: "Cormorant Garamond", sans-serif;
  font-size: 2vw;
  text-transform: uppercase;
  letter-spacing: 1vw;
  position: absolute;
  top: 3vw;
}
.Project_32-Project-Page-Cont .credits-cont .description {
  height: 100%;
  width: 50%;
  padding-top: 1vw;
  display: flex;
  flex-direction: column;
}
.Project_32-Project-Page-Cont .credits-cont .description .description-row {
  width: 100%;
  height: 6vw;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid gray;
}

.Project_32-Project-Page-Cont .credits-cont .description .description-row span{
  font-family: "Cormorant Garamond", sans-serif;
  font-size: 1.5vw;
  text-transform: uppercase;
  letter-spacing: 3px;
}

@media (max-width: 1366px) {
  .Project_32-Project-Page-Cont .hero-section .hero-video {
    width: 127.5vh !important;
    height: 71.74vh !important;
  }
  .Project_32-Project-Page-Cont .images-cont .image-row .no-image {
    display: none;
  }
  .Project_32-Project-Page-Cont .images-cont .image-row .image {
    width: 100%;
  }
  .Project_32-Project-Page-Cont .images-cont .image-row .image img {
    width: 65vh;
  }
  .Project_32-Project-Page-Cont
    .credits-cont
    .description
    .description-row
    span {
    font-size: 2vh;
  }
}

@media (max-width: 900px) {
  .Project_32-Project-Page-Cont .credits-cont {
    flex-direction: column;
    align-items: center;
  }
  .Project_32-Project-Page-Cont .credits-cont .cridits-title {
    height: auto;

    display: flex;
    justify-content: center;
    width: 100%;
  }
  .Project_32-Project-Page-Cont .credits-cont .description {
    height: 80%;
    margin-top: 10vh;
    width: 100%;
  }
  .Project_32-Project-Page-Cont
    .credits-cont
    .description
    .description-row
    span {
    font-size: 3vh;
  }
  .Project_32-Project-Page-Cont .credits-cont .cridits-title span {
    position: relative;
    font-size: 5vh;
  }
  .Project_32-Project-Page-Cont .hero-section .icons a {
    width: 6vh;
    margin: 0 1vh;
  }
}

@media (max-width: 770px) {
  .Project_32-Project-Page-Cont .hero-section .icons {
    width: 16vh;
  }
}
@media (max-width: 600px) {
  .Project_32-Project-Page-Cont .hero-section .icons {
    width: 15vh;
    top: 6vh;
    right: 3vh;
  }
  .Project_32-Project-Page-Cont
    .credits-cont
    .description
    .description-row
    span {
    font-size: 2vh;
  }
  .Project_32-Project-Page-Cont
    .credits-cont
    .description
    .description-row{
      height: 5vh;
    }
    .Project_32-Project-Page-Cont
    .credits-cont{
      width: 100%;
    }
}
@media (max-width: 420px) {
  .Project_32-Project-Page-Cont {
    width: 110%;
  }
  .Project_32-Project-Page-Cont
    .credits-cont
    .description
    .description-row
    span {
    font-size: 2vh;
  }
  .Project_32-Project-Page-Cont
    .credits-cont
    .description
    .description-row{
      height: 5vh;
    }
}


.Project_33-Project-Page-Cont {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-x: hidden;
}

.Project_33-Project-Page-Cont .hero-section {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Project_33-Project-Page-Cont .hero-section .icons {
  position: absolute;
  width: 10vw;
  z-index: 999;
  display: flex;
  right: 0;
  top: 3vw;
}

.Project_33-Project-Page-Cont .hero-section .icons a {
  width: 2vw;
  margin: 0 .6vw;
}

.Project_33-Project-Page-Cont .hero-section .icons a svg {
  width: 100%;
}

.Project_33-Project-Page-Cont .hero-section .hero-video {
  width: 75vw !important;
  height: 42.2vw !important;
}

.Project_33-Project-Page-Cont .images-cont {
  width: 100%;
  height: auto;
  margin-top: 8vw;
}

.Project_33-Project-Page-Cont .images-cont .image-row {
  width: 100%;
  height: 100vh;
  display: flex;
}

.Project_33-Project-Page-Cont .images-cont .image-row .image {
  height: 100%;
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Project_33-Project-Page-Cont .images-cont .image-row .image img {
  width: 35vw;
}

.Project_33-Project-Page-Cont .images-cont .image-row .no-image {
  height: 100%;
  width: 50%;
}

.Project_33-Project-Page-Cont .credits-cont {
  width: 90%;
  height: 80vh;
  margin-top: 5vw;
  display: flex;
  border-top: 1px solid gray;
}

.Project_33-Project-Page-Cont .credits-cont .cridits-title {
  height: 100%;
  width: 50%;
  position: relative;
}

.Project_33-Project-Page-Cont .credits-cont .cridits-title span {
  font-family: "Cormorant Garamond", sans-serif;
  font-size: 2vw;
  text-transform: uppercase;
  letter-spacing: 1vw;
  position: absolute;
  top: 3vw;
}
.Project_33-Project-Page-Cont .credits-cont .description {
  height: 100%;
  width: 50%;
  padding-top: 1vw;
  display: flex;
  flex-direction: column;
}
.Project_33-Project-Page-Cont .credits-cont .description .description-row {
  width: 100%;
  height: 6vw;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid gray;
}

.Project_33-Project-Page-Cont .credits-cont .description .description-row span{
  font-family: "Cormorant Garamond", sans-serif;
  font-size: 1.5vw;
  text-transform: uppercase;
  letter-spacing: 3px;
}

@media (max-width: 1366px) {
  .Project_33-Project-Page-Cont .hero-section .hero-video {
    width: 127.5vh !important;
    height: 71.74vh !important;
  }
  .Project_33-Project-Page-Cont .images-cont .image-row .no-image {
    display: none;
  }
  .Project_33-Project-Page-Cont .images-cont .image-row .image {
    width: 100%;
  }
  .Project_33-Project-Page-Cont .images-cont .image-row .image img {
    width: 65vh;
  }
  .Project_33-Project-Page-Cont
    .credits-cont
    .description
    .description-row
    span {
    font-size: 2vh;
  }
}

@media (max-width: 900px) {
  .Project_33-Project-Page-Cont .credits-cont {
    flex-direction: column;
    align-items: center;
  }
  .Project_33-Project-Page-Cont .credits-cont .cridits-title {
    height: auto;

    display: flex;
    justify-content: center;
    width: 100%;
  }
  .Project_33-Project-Page-Cont .credits-cont .description {
    height: 80%;
    margin-top: 10vh;
    width: 100%;
  }
  .Project_33-Project-Page-Cont
    .credits-cont
    .description
    .description-row
    span {
    font-size: 3vh;
  }
  .Project_33-Project-Page-Cont .credits-cont .cridits-title span {
    position: relative;
    font-size: 5vh;
  }
  .Project_33-Project-Page-Cont .hero-section .icons a {
    width: 6vh;
    margin: 0 1vh;
  }
}

@media (max-width: 770px) {
  .Project_33-Project-Page-Cont .hero-section .icons {
    width: 16vh;
  }
}
@media (max-width: 600px) {
  .Project_33-Project-Page-Cont .hero-section .icons {
    width: 15vh;
    top: 6vh;
    right: 3vh;
  }
  .Project_33-Project-Page-Cont
    .credits-cont
    .description
    .description-row
    span {
    font-size: 2vh;
  }
  .Project_33-Project-Page-Cont
    .credits-cont
    .description
    .description-row{
      height: 5vh;
    }
    .Project_33-Project-Page-Cont
    .credits-cont{
      width: 100%;
    }
}
@media (max-width: 420px) {
  .Project_33-Project-Page-Cont {
    width: 110%;
  }
  .Project_33-Project-Page-Cont
    .credits-cont
    .description
    .description-row
    span {
    font-size: 2vh;
  }
  .Project_33-Project-Page-Cont
    .credits-cont
    .description
    .description-row{
      height: 5vh;
    }
}

.Project_41-Project-Page-Cont {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-x: hidden;
}

.Project_41-Project-Page-Cont .hero-section {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Project_41-Project-Page-Cont .hero-section .icons {
  position: absolute;
  width: 10vw;
  z-index: 999;
  display: flex;
  right: 0;
  top: 3vw;
}

.Project_41-Project-Page-Cont .hero-section .icons a {
  width: 2vw;
  margin: 0 .6vw;
}

.Project_41-Project-Page-Cont .hero-section .icons a svg {
  width: 100%;
}

.Project_41-Project-Page-Cont .hero-section .hero-video {
  width: 75vw !important;
  height: 42.2vw !important;
}

.Project_41-Project-Page-Cont .images-cont {
  width: 100%;
  height: auto;
  margin-top: 8vw;
}

.Project_41-Project-Page-Cont .images-cont .image-row {
  width: 100%;
  height: 100vh;
  display: flex;
}

.Project_41-Project-Page-Cont .images-cont .image-row .image {
  height: 100%;
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Project_41-Project-Page-Cont .images-cont .image-row .image img {
  width: 35vw;
}

.Project_41-Project-Page-Cont .images-cont .image-row .no-image {
  height: 100%;
  width: 50%;
}

.Project_41-Project-Page-Cont .credits-cont {
  width: 90%;
  height: 80vh;
  margin-top: 5vw;
  display: flex;
  border-top: 1px solid gray;
}

.Project_41-Project-Page-Cont .credits-cont .cridits-title {
  height: 100%;
  width: 50%;
  position: relative;
}

.Project_41-Project-Page-Cont .credits-cont .cridits-title span {
  font-family: "Cormorant Garamond", sans-serif;
  font-size: 2vw;
  text-transform: uppercase;
  letter-spacing: 1vw;
  position: absolute;
  top: 3vw;
}
.Project_41-Project-Page-Cont .credits-cont .description {
  height: 100%;
  width: 50%;
  padding-top: 1vw;
  display: flex;
  flex-direction: column;
}
.Project_41-Project-Page-Cont .credits-cont .description .description-row {
  width: 100%;
  height: 6vw;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid gray;
}

.Project_41-Project-Page-Cont .credits-cont .description .description-row span{
  font-family: "Cormorant Garamond", sans-serif;
  font-size: 1.5vw;
  text-transform: uppercase;
  letter-spacing: 3px;
}

@media (max-width: 1366px) {
  .Project_41-Project-Page-Cont .hero-section .hero-video {
    width: 127.5vh !important;
    height: 71.74vh !important;
  }
  .Project_41-Project-Page-Cont .images-cont .image-row .no-image {
    display: none;
  }
  .Project_41-Project-Page-Cont .images-cont .image-row .image {
    width: 100%;
  }
  .Project_41-Project-Page-Cont .images-cont .image-row .image img {
    width: 65vh;
  }
  .Project_41-Project-Page-Cont
    .credits-cont
    .description
    .description-row
    span {
    font-size: 2vh;
  }
}

@media (max-width: 900px) {
  .Project_41-Project-Page-Cont .credits-cont {
    flex-direction: column;
    align-items: center;
  }
  .Project_41-Project-Page-Cont .credits-cont .cridits-title {
    height: auto;

    display: flex;
    justify-content: center;
    width: 100%;
  }
  .Project_41-Project-Page-Cont .credits-cont .description {
    height: 80%;
    margin-top: 10vh;
    width: 100%;
  }
  .Project_41-Project-Page-Cont
    .credits-cont
    .description
    .description-row
    span {
    font-size: 3vh;
  }
  .Project_41-Project-Page-Cont .credits-cont .cridits-title span {
    position: relative;
    font-size: 5vh;
  }
  .Project_41-Project-Page-Cont .hero-section .icons a {
    width: 6vh;
    margin: 0 1vh;
  }
}

@media (max-width: 770px) {
  .Project_41-Project-Page-Cont .hero-section .icons {
    width: 16vh;
  }
}
@media (max-width: 600px) {
  .Project_41-Project-Page-Cont .hero-section .icons {
    width: 15vh;
    top: 6vh;
    right: 3vh;
  }
  .Project_41-Project-Page-Cont
    .credits-cont
    .description
    .description-row
    span {
    font-size: 2vh;
  }
  .Project_41-Project-Page-Cont
    .credits-cont
    .description
    .description-row{
      height: 5vh;
    }
    .Project_41-Project-Page-Cont
    .credits-cont{
      width: 100%;
    }
}
@media (max-width: 420px) {
  .Project_41-Project-Page-Cont {
    width: 110%;
  }
  .Project_41-Project-Page-Cont
    .credits-cont
    .description
    .description-row
    span {
    font-size: 2vh;
  }
  .Project_41-Project-Page-Cont
    .credits-cont
    .description
    .description-row{
      height: 5vh;
    }
}


.Project_42-Project-Page-Cont {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-x: hidden;
}

.Project_42-Project-Page-Cont .hero-section {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Project_42-Project-Page-Cont .hero-section .icons {
  position: absolute;
  width: 10vw;
  z-index: 999;
  display: flex;
  right: 0;
  top: 3vw;
}

.Project_42-Project-Page-Cont .hero-section .icons a {
  width: 2vw;
  margin: 0 .6vw;
}

.Project_42-Project-Page-Cont .hero-section .icons a svg {
  width: 100%;
}

.Project_42-Project-Page-Cont .hero-section .hero-video {
  width: 75vw !important;
  height: 42.2vw !important;
}

.Project_42-Project-Page-Cont .images-cont {
  width: 100%;
  height: auto;
  margin-top: 8vw;
}

.Project_42-Project-Page-Cont .images-cont .image-row {
  width: 100%;
  height: 100vh;
  display: flex;
}

.Project_42-Project-Page-Cont .images-cont .image-row .image {
  height: 100%;
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Project_42-Project-Page-Cont .images-cont .image-row .image img {
  width: 35vw;
}

.Project_42-Project-Page-Cont .images-cont .image-row .no-image {
  height: 100%;
  width: 50%;
}

.Project_42-Project-Page-Cont .credits-cont {
  width: 90%;
  height: 80vh;
  margin-top: 5vw;
  display: flex;
  border-top: 1px solid gray;
}

.Project_42-Project-Page-Cont .credits-cont .cridits-title {
  height: 100%;
  width: 50%;
  position: relative;
}

.Project_42-Project-Page-Cont .credits-cont .cridits-title span {
  font-family: "Cormorant Garamond", sans-serif;
  font-size: 2vw;
  text-transform: uppercase;
  letter-spacing: 1vw;
  position: absolute;
  top: 3vw;
}
.Project_42-Project-Page-Cont .credits-cont .description {
  height: 100%;
  width: 50%;
  padding-top: 1vw;
  display: flex;
  flex-direction: column;
}
.Project_42-Project-Page-Cont .credits-cont .description .description-row {
  width: 100%;
  height: 6vw;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid gray;
}

.Project_42-Project-Page-Cont .credits-cont .description .description-row span{
  font-family: "Cormorant Garamond", sans-serif;
  font-size: 1.5vw;
  text-transform: uppercase;
  letter-spacing: 3px;
}

@media (max-width: 1366px) {
  .Project_42-Project-Page-Cont .hero-section .hero-video {
    width: 127.5vh !important;
    height: 71.74vh !important;
  }
  .Project_42-Project-Page-Cont .images-cont .image-row .no-image {
    display: none;
  }
  .Project_42-Project-Page-Cont .images-cont .image-row .image {
    width: 100%;
  }
  .Project_42-Project-Page-Cont .images-cont .image-row .image img {
    width: 65vh;
  }
  .Project_42-Project-Page-Cont
    .credits-cont
    .description
    .description-row
    span {
    font-size: 2vh;
  }
}

@media (max-width: 900px) {
  .Project_42-Project-Page-Cont .credits-cont {
    flex-direction: column;
    align-items: center;
  }
  .Project_42-Project-Page-Cont .credits-cont .cridits-title {
    height: auto;

    display: flex;
    justify-content: center;
    width: 100%;
  }
  .Project_42-Project-Page-Cont .credits-cont .description {
    height: 80%;
    margin-top: 10vh;
    width: 100%;
  }
  .Project_42-Project-Page-Cont
    .credits-cont
    .description
    .description-row
    span {
    font-size: 3vh;
  }
  .Project_42-Project-Page-Cont .credits-cont .cridits-title span {
    position: relative;
    font-size: 5vh;
  }
  .Project_42-Project-Page-Cont .hero-section .icons a {
    width: 6vh;
    margin: 0 1vh;
  }
}

@media (max-width: 770px) {
  .Project_42-Project-Page-Cont .hero-section .icons {
    width: 16vh;
  }
}
@media (max-width: 600px) {
  .Project_42-Project-Page-Cont .hero-section .icons {
    width: 15vh;
    top: 6vh;
    right: 3vh;
  }
  .Project_42-Project-Page-Cont
    .credits-cont
    .description
    .description-row
    span {
    font-size: 2vh;
  }
  .Project_42-Project-Page-Cont
    .credits-cont
    .description
    .description-row{
      height: 5vh;
    }
    .Project_42-Project-Page-Cont
    .credits-cont{
      width: 100%;
    }
}
@media (max-width: 420px) {
  .Project_42-Project-Page-Cont {
    width: 110%;
  }
  .Project_42-Project-Page-Cont
    .credits-cont
    .description
    .description-row
    span {
    font-size: 2vh;
  }
  .Project_42-Project-Page-Cont
    .credits-cont
    .description
    .description-row{
      height: 5vh;
    }
}


.Project_43-Project-Page-Cont {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-x: hidden;
}

.Project_43-Project-Page-Cont .hero-section {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Project_43-Project-Page-Cont .hero-section .icons {
  position: absolute;
  width: 10vw;
  z-index: 999;
  display: flex;
  right: 0;
  top: 3vw;
}

.Project_43-Project-Page-Cont .images-cont .icons a {
  width: 2vw;
  margin: 0 .6vw;
}

.Project_43-Project-Page-Cont .images-cont .icons a svg {
  width: 100%;
}

.Project_43-Project-Page-Cont .hero-section .hero-video {
  width: 75vw !important;
  height: 42.2vw !important;
}

.Project_43-Project-Page-Cont .images-cont {
  width: 100%;
  height: auto;
  margin-top: 8vw;
}

.Project_43-Project-Page-Cont .images-cont .image-row {
  width: 100%;
  height: 100vh;
  display: flex;
}

.Project_43-Project-Page-Cont .images-cont .image-row .image {
  height: 100%;
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Project_43-Project-Page-Cont .images-cont .image-row .image img {
  width: 35vw;
}

.Project_43-Project-Page-Cont .images-cont .image-row .no-image {
  height: 100%;
  width: 50%;
}

.Project_43-Project-Page-Cont .credits-cont {
  width: 90%;
  height: 80vh;
  margin-top: 5vw;
  display: flex;
  border-top: 1px solid gray;
}

.Project_43-Project-Page-Cont .credits-cont .cridits-title {
  height: 100%;
  width: 50%;
  position: relative;
}

.Project_43-Project-Page-Cont .credits-cont .cridits-title span {
  font-family: "Cormorant Garamond", sans-serif;
  font-size: 2vw;
  text-transform: uppercase;
  letter-spacing: 1vw;
  position: absolute;
  top: 3vw;
}
.Project_43-Project-Page-Cont .credits-cont .description {
  height: 100%;
  width: 50%;
  padding-top: 1vw;
  display: flex;
  flex-direction: column;
}
.Project_43-Project-Page-Cont .credits-cont .description .description-row {
  width: 100%;
  height: 6vw;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid gray;
}

.Project_43-Project-Page-Cont .credits-cont .description .description-row span{
  font-family: "Cormorant Garamond", sans-serif;
  font-size: 1.5vw;
  text-transform: uppercase;
  letter-spacing: 3px;
}

@media (max-width: 1366px) {
  .Project_43-Project-Page-Cont .hero-section .hero-video {
    width: 127.5vh !important;
    height: 71.74vh !important;
  }
  .Project_43-Project-Page-Cont .images-cont .image-row .no-image {
    display: none;
  }
  .Project_43-Project-Page-Cont .images-cont .image-row .image {
    width: 100%;
  }
  .Project_43-Project-Page-Cont .images-cont .image-row .image img {
    width: 65vh;
  }
  .Project_43-Project-Page-Cont
    .credits-cont
    .description
    .description-row
    span {
    font-size: 2vh;
  }
}

@media (max-width: 900px) {
  .Project_43-Project-Page-Cont .credits-cont {
    flex-direction: column;
    align-items: center;
  }
  .Project_43-Project-Page-Cont .credits-cont .cridits-title {
    height: auto;

    display: flex;
    justify-content: center;
    width: 100%;
  }
  .Project_43-Project-Page-Cont .credits-cont .description {
    height: 80%;
    margin-top: 10vh;
    width: 100%;
  }
  .Project_43-Project-Page-Cont
    .credits-cont
    .description
    .description-row
    span {
    font-size: 3vh;
  }
  .Project_43-Project-Page-Cont .credits-cont .cridits-title span {
    position: relative;
    font-size: 5vh;
  }
  .Project_43-Project-Page-Cont .images-cont .icons a {
    width: 6vh;
    margin: 0 1vh;
  }
}

@media (max-width: 770px) {
  .Project_43-Project-Page-Cont .images-cont .icons {
    width: 16vh;
  }
}
@media (max-width: 600px) {
  .Project_43-Project-Page-Cont .images-cont .icons {
    width: 15vh;
    top: 2vh;
    right: 3vh;
  }
  .Project_43-Project-Page-Cont
    .credits-cont
    .description
    .description-row
    span {
    font-size: 2vh;
  }
  .Project_43-Project-Page-Cont
    .credits-cont
    .description
    .description-row{
      height: 5vh;
    }
    .Project_43-Project-Page-Cont
    .credits-cont{
      width: 100%;
    }
}
@media (max-width: 420px) {
  .Project_43-Project-Page-Cont {
    width: 110%;
  }
  .Project_43-Project-Page-Cont
    .credits-cont
    .description
    .description-row
    span {
    font-size: 2vh;
  }
  .Project_43-Project-Page-Cont
    .credits-cont
    .description
    .description-row{
      height: 5vh;
    }
}
.Project_51-Project-Page-Cont {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-x: hidden;
}

.Project_51-Project-Page-Cont .hero-section {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Project_51-Project-Page-Cont .hero-section .icons {
  position: absolute;
  width: 10vw;
  z-index: 999;
  display: flex;
  right: 0;
  top: 3vw;
}

.Project_51-Project-Page-Cont .hero-section .icons a {
  width: 2vw;
  margin: 0 .6vw;
}

.Project_51-Project-Page-Cont .hero-section .icons a svg {
  width: 100%;
}

.Project_51-Project-Page-Cont .hero-section .hero-video {
  width: 75vw !important;
  height: 42.2vw !important;
}

.Project_51-Project-Page-Cont .images-cont {
  width: 100%;
  height: auto;
  margin-top: 8vw;
}

.Project_51-Project-Page-Cont .images-cont .image-row {
  width: 100%;
  height: 100vh;
  display: flex;
}

.Project_51-Project-Page-Cont .images-cont .image-row .image {
  height: 100%;
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Project_51-Project-Page-Cont .images-cont .image-row .image img {
  width: 35vw;
}

.Project_51-Project-Page-Cont .images-cont .image-row .no-image {
  height: 100%;
  width: 50%;
}

.Project_51-Project-Page-Cont .credits-cont {
  width: 90%;
  height: 80vh;
  margin-top: 5vw;
  display: flex;
  border-top: 1px solid gray;
}

.Project_51-Project-Page-Cont .credits-cont .cridits-title {
  height: 100%;
  width: 50%;
  position: relative;
}

.Project_51-Project-Page-Cont .credits-cont .cridits-title span {
  font-family: "Cormorant Garamond", sans-serif;
  font-size: 2vw;
  text-transform: uppercase;
  letter-spacing: 1vw;
  position: absolute;
  top: 3vw;
}
.Project_51-Project-Page-Cont .credits-cont .description {
  height: 100%;
  width: 50%;
  padding-top: 1vw;
  display: flex;
  flex-direction: column;
}
.Project_51-Project-Page-Cont .credits-cont .description .description-row {
  width: 100%;
  height: 6vw;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid gray;
}

.Project_51-Project-Page-Cont .credits-cont .description .description-row span{
  font-family: "Cormorant Garamond", sans-serif;
  font-size: 1.5vw;
  text-transform: uppercase;
  letter-spacing: 3px;
}

@media (max-width: 1366px) {
  .Project_51-Project-Page-Cont .hero-section .hero-video {
    width: 127.5vh !important;
    height: 71.74vh !important;
  }
  .Project_51-Project-Page-Cont .images-cont .image-row .no-image {
    display: none;
  }
  .Project_51-Project-Page-Cont .images-cont .image-row .image {
    width: 100%;
  }
  .Project_51-Project-Page-Cont .images-cont .image-row .image img {
    width: 65vh;
  }
  .Project_51-Project-Page-Cont
    .credits-cont
    .description
    .description-row
    span {
    font-size: 2vh;
  }
}

@media (max-width: 900px) {
  .Project_51-Project-Page-Cont .credits-cont {
    flex-direction: column;
    align-items: center;
  }
  .Project_51-Project-Page-Cont .credits-cont .cridits-title {
    height: auto;

    display: flex;
    justify-content: center;
    width: 100%;
  }
  .Project_51-Project-Page-Cont .credits-cont .description {
    height: 80%;
    margin-top: 10vh;
    width: 100%;
  }
  .Project_51-Project-Page-Cont
    .credits-cont
    .description
    .description-row
    span {
    font-size: 3vh;
  }
  .Project_51-Project-Page-Cont .credits-cont .cridits-title span {
    position: relative;
    font-size: 5vh;
  }
  .Project_51-Project-Page-Cont .hero-section .icons a {
    width: 6vh;
    margin: 0 1vh;
  }
}

@media (max-width: 770px) {
  .Project_51-Project-Page-Cont .hero-section .icons {
    width: 16vh;
  }
}
@media (max-width: 600px) {
  .Project_51-Project-Page-Cont .hero-section .icons {
    width: 15vh;
    top: 6vh;
    right: 3vh;
  }
  .Project_51-Project-Page-Cont
    .credits-cont
    .description
    .description-row
    span {
    font-size: 2vh;
  }
  .Project_51-Project-Page-Cont
    .credits-cont
    .description
    .description-row{
      height: 5vh;
    }
    .Project_51-Project-Page-Cont
    .credits-cont{
      width: 100%;
    }
}
@media (max-width: 420px) {
  .Project_51-Project-Page-Cont {
    width: 110%;
  }
  .Project_51-Project-Page-Cont
    .credits-cont
    .description
    .description-row
    span {
    font-size: 2vh;
  }
  .Project_51-Project-Page-Cont
    .credits-cont
    .description
    .description-row{
      height: 5vh;
    }
}
.Project_52-Project-Page-Cont {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-x: hidden;
}

.Project_52-Project-Page-Cont .hero-section {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Project_52-Project-Page-Cont .hero-section .icons {
  position: absolute;
  width: 10vw;
  z-index: 999;
  display: flex;
  right: 0;
  top: 3vw;
}

.Project_52-Project-Page-Cont .hero-section .icons a {
  width: 2vw;
  margin: 0 .6vw;
}

.Project_52-Project-Page-Cont .hero-section .icons a svg {
  width: 100%;
}

.Project_52-Project-Page-Cont .hero-section .hero-video {
  width: 75vw !important;
  height: 42.2vw !important;
}

.Project_52-Project-Page-Cont .images-cont {
  width: 100%;
  height: auto;
  margin-top: 8vw;
}

.Project_52-Project-Page-Cont .images-cont .image-row {
  width: 100%;
  height: 100vh;
  display: flex;
}

.Project_52-Project-Page-Cont .images-cont .image-row .image {
  height: 100%;
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Project_52-Project-Page-Cont .images-cont .image-row .image img {
  width: 35vw;
}

.Project_52-Project-Page-Cont .images-cont .image-row .no-image {
  height: 100%;
  width: 50%;
}

.Project_52-Project-Page-Cont .credits-cont {
  width: 90%;
  height: 80vh;
  margin-top: 5vw;
  display: flex;
  border-top: 1px solid gray;
}

.Project_52-Project-Page-Cont .credits-cont .cridits-title {
  height: 100%;
  width: 50%;
  position: relative;
}

.Project_52-Project-Page-Cont .credits-cont .cridits-title span {
  font-family: "Cormorant Garamond", sans-serif;
  font-size: 2vw;
  text-transform: uppercase;
  letter-spacing: 1vw;
  position: absolute;
  top: 3vw;
}
.Project_52-Project-Page-Cont .credits-cont .description {
  height: 100%;
  width: 50%;
  padding-top: 1vw;
  display: flex;
  flex-direction: column;
}
.Project_52-Project-Page-Cont .credits-cont .description .description-row {
  width: 100%;
  height: 6vw;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid gray;
}

.Project_52-Project-Page-Cont .credits-cont .description .description-row span{
  font-family: "Cormorant Garamond", sans-serif;
  font-size: 1.5vw;
  text-transform: uppercase;
  letter-spacing: 3px;
}

@media (max-width: 1366px) {
  .Project_52-Project-Page-Cont .hero-section .hero-video {
    width: 127.5vh !important;
    height: 71.74vh !important;
  }
  .Project_52-Project-Page-Cont .images-cont .image-row .no-image {
    display: none;
  }
  .Project_52-Project-Page-Cont .images-cont .image-row .image {
    width: 100%;
  }
  .Project_52-Project-Page-Cont .images-cont .image-row .image img {
    width: 65vh;
  }
  .Project_52-Project-Page-Cont
    .credits-cont
    .description
    .description-row
    span {
    font-size: 2vh;
  }
}

@media (max-width: 900px) {
  .Project_52-Project-Page-Cont .credits-cont {
    flex-direction: column;
    align-items: center;
  }
  .Project_52-Project-Page-Cont .credits-cont .cridits-title {
    height: auto;

    display: flex;
    justify-content: center;
    width: 100%;
  }
  .Project_52-Project-Page-Cont .credits-cont .description {
    height: 80%;
    margin-top: 10vh;
    width: 100%;
  }
  .Project_52-Project-Page-Cont
    .credits-cont
    .description
    .description-row
    span {
    font-size: 3vh;
  }
  .Project_52-Project-Page-Cont .credits-cont .cridits-title span {
    position: relative;
    font-size: 5vh;
  }
  .Project_52-Project-Page-Cont .hero-section .icons a {
    width: 6vh;
    margin: 0 1vh;
  }
}

@media (max-width: 770px) {
  .Project_52-Project-Page-Cont .hero-section .icons {
    width: 16vh;
  }
}
@media (max-width: 600px) {
  .Project_52-Project-Page-Cont .hero-section .icons {
    width: 15vh;
    top: 6vh;
    right: 3vh;
  }
  .Project_52-Project-Page-Cont
    .credits-cont
    .description
    .description-row
    span {
    font-size: 2vh;
  }
  .Project_52-Project-Page-Cont
    .credits-cont
    .description
    .description-row{
      height: 5vh;
    }
    .Project_52-Project-Page-Cont
    .credits-cont{
      width: 100%;
    }
}
@media (max-width: 420px) {
  .Project_52-Project-Page-Cont {
    width: 110%;
  }
  .Project_52-Project-Page-Cont
    .credits-cont
    .description
    .description-row
    span {
    font-size: 2vh;
  }
  .Project_52-Project-Page-Cont
    .credits-cont
    .description
    .description-row{
      height: 5vh;
    }
}


.Project_53-Project-Page-Cont {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-x: hidden;
}

.Project_53-Project-Page-Cont .hero-section {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Project_53-Project-Page-Cont .hero-section .icons {
  position: absolute;
  width: 10vw;
  z-index: 999;
  display: flex;
  right: 0;
  top: 3vw;
}

.Project_53-Project-Page-Cont .hero-section .icons a {
  width: 2vw;
  margin: 0 .6vw;
}

.Project_53-Project-Page-Cont .hero-section .icons a svg {
  width: 100%;
}

.Project_53-Project-Page-Cont .hero-section .hero-video {
  width: 75vw !important;
  height: 42.2vw !important;
}

.Project_53-Project-Page-Cont .images-cont {
  width: 100%;
  height: auto;
  margin-top: 8vw;
}

.Project_53-Project-Page-Cont .images-cont .image-row {
  width: 100%;
  height: 100vh;
  display: flex;
}

.Project_53-Project-Page-Cont .images-cont .image-row .image {
  height: 100%;
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Project_53-Project-Page-Cont .images-cont .image-row .image img {
  width: 35vw;
}

.Project_53-Project-Page-Cont .images-cont .image-row .no-image {
  height: 100%;
  width: 50%;
}

.Project_53-Project-Page-Cont .credits-cont {
  width: 90%;
  height: 80vh;
  margin-top: 5vw;
  display: flex;
  border-top: 1px solid gray;
}

.Project_53-Project-Page-Cont .credits-cont .cridits-title {
  height: 100%;
  width: 50%;
  position: relative;
}

.Project_53-Project-Page-Cont .credits-cont .cridits-title span {
  font-family: "Cormorant Garamond", sans-serif;
  font-size: 2vw;
  text-transform: uppercase;
  letter-spacing: 1vw;
  position: absolute;
  top: 3vw;
}
.Project_53-Project-Page-Cont .credits-cont .description {
  height: 100%;
  width: 50%;
  padding-top: 1vw;
  display: flex;
  flex-direction: column;
}
.Project_53-Project-Page-Cont .credits-cont .description .description-row {
  width: 100%;
  height: 6vw;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid gray;
}

.Project_53-Project-Page-Cont .credits-cont .description .description-row span{
  font-family: "Cormorant Garamond", sans-serif;
  font-size: 1.5vw;
  text-transform: uppercase;
  letter-spacing: 3px;
}

@media (max-width: 1366px) {
  .Project_53-Project-Page-Cont .hero-section .hero-video {
    width: 127.5vh !important;
    height: 71.74vh !important;
  }
  .Project_53-Project-Page-Cont .images-cont .image-row .no-image {
    display: none;
  }
  .Project_53-Project-Page-Cont .images-cont .image-row .image {
    width: 100%;
  }
  .Project_53-Project-Page-Cont .images-cont .image-row .image img {
    width: 65vh;
  }
  .Project_53-Project-Page-Cont
    .credits-cont
    .description
    .description-row
    span {
    font-size: 2vh;
  }
}

@media (max-width: 900px) {
  .Project_53-Project-Page-Cont .credits-cont {
    flex-direction: column;
    align-items: center;
  }
  .Project_53-Project-Page-Cont .credits-cont .cridits-title {
    height: auto;

    display: flex;
    justify-content: center;
    width: 100%;
  }
  .Project_53-Project-Page-Cont .credits-cont .description {
    height: 80%;
    margin-top: 10vh;
    width: 100%;
  }
  .Project_53-Project-Page-Cont
    .credits-cont
    .description
    .description-row
    span {
    font-size: 3vh;
  }
  .Project_53-Project-Page-Cont .credits-cont .cridits-title span {
    position: relative;
    font-size: 5vh;
  }
  .Project_53-Project-Page-Cont .hero-section .icons a {
    width: 6vh;
    margin: 0 1vh;
  }
}

@media (max-width: 770px) {
  .Project_53-Project-Page-Cont .hero-section .icons {
    width: 16vh;
  }
}
@media (max-width: 600px) {
  .Project_53-Project-Page-Cont .hero-section .icons {
    width: 15vh;
    top: 6vh;
    right: 3vh;
  }
  .Project_53-Project-Page-Cont
    .credits-cont
    .description
    .description-row
    span {
    font-size: 2vh;
  }
  .Project_53-Project-Page-Cont
    .credits-cont
    .description
    .description-row{
      height: 5vh;
    }
    .Project_53-Project-Page-Cont
    .credits-cont{
      width: 100%;
    }
}
@media (max-width: 420px) {
  .Project_53-Project-Page-Cont {
    width: 110%;
  }
  .Project_53-Project-Page-Cont
    .credits-cont
    .description
    .description-row
    span {
    font-size: 2vh;
  }
  .Project_53-Project-Page-Cont
    .credits-cont
    .description
    .description-row{
      height: 5vh;
    }
}


.Project_61-Project-Page-Cont {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-x: hidden;
}

.Project_61-Project-Page-Cont .hero-section {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Project_61-Project-Page-Cont .hero-section .icons {
  position: absolute;
  width: 10vw;
  z-index: 999;
  display: flex;
  right: 0;
  top: 3vw;
}

.Project_61-Project-Page-Cont .hero-section .icons a {
  width: 2vw;
  margin: 0 .6vw;
}

.Project_61-Project-Page-Cont .hero-section .icons a svg {
  width: 100%;
}

.Project_61-Project-Page-Cont .hero-section .hero-video {
  width: 75vw !important;
  height: 42.2vw !important;
}

.Project_61-Project-Page-Cont .images-cont {
  width: 100%;
  height: auto;
  margin-top: 8vw;
}

.Project_61-Project-Page-Cont .images-cont .image-row {
  width: 100%;
  height: 100vh;
  display: flex;
}

.Project_61-Project-Page-Cont .images-cont .image-row .image {
  height: 100%;
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Project_61-Project-Page-Cont .images-cont .image-row .image img {
  width: 35vw;
}

.Project_61-Project-Page-Cont .images-cont .image-row .no-image {
  height: 100%;
  width: 50%;
}

.Project_61-Project-Page-Cont .credits-cont {
  width: 90%;
  height: 80vh;
  margin-top: 5vw;
  display: flex;
  border-top: 1px solid gray;
}

.Project_61-Project-Page-Cont .credits-cont .cridits-title {
  height: 100%;
  width: 50%;
  position: relative;
}

.Project_61-Project-Page-Cont .credits-cont .cridits-title span {
  font-family: "Cormorant Garamond", sans-serif;
  font-size: 2vw;
  text-transform: uppercase;
  letter-spacing: 1vw;
  position: absolute;
  top: 3vw;
}
.Project_61-Project-Page-Cont .credits-cont .description {
  height: 100%;
  width: 50%;
  padding-top: 1vw;
  display: flex;
  flex-direction: column;
}
.Project_61-Project-Page-Cont .credits-cont .description .description-row {
  width: 100%;
  height: 6vw;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid gray;
}

.Project_61-Project-Page-Cont .credits-cont .description .description-row span{
  font-family: "Cormorant Garamond", sans-serif;
  font-size: 1.5vw;
  text-transform: uppercase;
  letter-spacing: 3px;
}

@media (max-width: 1366px) {
  .Project_61-Project-Page-Cont .hero-section .hero-video {
    width: 127.5vh !important;
    height: 71.74vh !important;
  }
  .Project_61-Project-Page-Cont .images-cont .image-row .no-image {
    display: none;
  }
  .Project_61-Project-Page-Cont .images-cont .image-row .image {
    width: 100%;
  }
  .Project_61-Project-Page-Cont .images-cont .image-row .image img {
    width: 65vh;
  }
  .Project_61-Project-Page-Cont
    .credits-cont
    .description
    .description-row
    span {
    font-size: 2vh;
  }
}

@media (max-width: 900px) {
  .Project_61-Project-Page-Cont .credits-cont {
    flex-direction: column;
    align-items: center;
  }
  .Project_61-Project-Page-Cont .credits-cont .cridits-title {
    height: auto;

    display: flex;
    justify-content: center;
    width: 100%;
  }
  .Project_61-Project-Page-Cont .credits-cont .description {
    height: 80%;
    margin-top: 10vh;
    width: 100%;
  }
  .Project_61-Project-Page-Cont
    .credits-cont
    .description
    .description-row
    span {
    font-size: 3vh;
  }
  .Project_61-Project-Page-Cont .credits-cont .cridits-title span {
    position: relative;
    font-size: 5vh;
  }
  .Project_61-Project-Page-Cont .hero-section .icons a {
    width: 6vh;
    margin: 0 1vh;
  }
}

@media (max-width: 770px) {
  .Project_61-Project-Page-Cont .hero-section .icons {
    width: 16vh;
  }
}
@media (max-width: 600px) {
  .Project_61-Project-Page-Cont .hero-section .icons {
    width: 15vh;
    top: 6vh;
    right: 3vh;
  }
  .Project_61-Project-Page-Cont
    .credits-cont
    .description
    .description-row
    span {
    font-size: 2vh;
  }
  .Project_61-Project-Page-Cont
    .credits-cont
    .description
    .description-row{
      height: 5vh;
    }
    .Project_61-Project-Page-Cont
    .credits-cont{
      width: 100%;
    }
}
@media (max-width: 420px) {
  .Project_61-Project-Page-Cont {
    width: 110%;
  }
  .Project_61-Project-Page-Cont
    .credits-cont
    .description
    .description-row
    span {
    font-size: 2vh;
  }
  .Project_61-Project-Page-Cont
    .credits-cont
    .description
    .description-row{
      height: 5vh;
    }
}


.Project_62-Project-Page-Cont {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-x: hidden;
}

.Project_62-Project-Page-Cont .hero-section {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Project_62-Project-Page-Cont .hero-section .icons {
  position: absolute;
  width: 10vw;
  z-index: 999;
  display: flex;
  right: 0;
  top: 3vw;
}

.Project_62-Project-Page-Cont .hero-section .icons a {
  width: 2vw;
  margin: 0 .6vw;
}

.Project_62-Project-Page-Cont .hero-section .icons a svg {
  width: 100%;
}

.Project_62-Project-Page-Cont .hero-section .hero-video {
  width: 75vw !important;
  height: 42.2vw !important;
}

.Project_62-Project-Page-Cont .images-cont {
  width: 100%;
  height: auto;
  margin-top: 8vw;
}

.Project_62-Project-Page-Cont .images-cont .image-row {
  width: 100%;
  height: 100vh;
  display: flex;
}

.Project_62-Project-Page-Cont .images-cont .image-row .image {
  height: 100%;
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Project_62-Project-Page-Cont .images-cont .image-row .image img {
  width: 35vw;
}

.Project_62-Project-Page-Cont .images-cont .image-row .no-image {
  height: 100%;
  width: 50%;
}

.Project_62-Project-Page-Cont .credits-cont {
  width: 90%;
  height: 80vh;
  margin-top: 5vw;
  display: flex;
  border-top: 1px solid gray;
}

.Project_62-Project-Page-Cont .credits-cont .cridits-title {
  height: 100%;
  width: 50%;
  position: relative;
}

.Project_62-Project-Page-Cont .credits-cont .cridits-title span {
  font-family: "Cormorant Garamond", sans-serif;
  font-size: 2vw;
  text-transform: uppercase;
  letter-spacing: 1vw;
  position: absolute;
  top: 3vw;
}
.Project_62-Project-Page-Cont .credits-cont .description {
  height: 100%;
  width: 50%;
  padding-top: 1vw;
  display: flex;
  flex-direction: column;
}
.Project_62-Project-Page-Cont .credits-cont .description .description-row {
  width: 100%;
  height: 6vw;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid gray;
}

.Project_62-Project-Page-Cont .credits-cont .description .description-row span{
  font-family: "Cormorant Garamond", sans-serif;
  font-size: 1.5vw;
  text-transform: uppercase;
  letter-spacing: 3px;
}
@media (max-width: 1366px) {
  .Project_62-Project-Page-Cont .hero-section .hero-video {
    width: 127.5vh !important;
    height: 71.74vh !important;
  }
  .Project_62-Project-Page-Cont .images-cont .image-row .no-image {
    display: none;
  }
  .Project_62-Project-Page-Cont .images-cont .image-row .image {
    width: 100%;
  }
  .Project_62-Project-Page-Cont .images-cont .image-row .image img {
    width: 65vh;
  }
  .Project_62-Project-Page-Cont
    .credits-cont
    .description
    .description-row
    span {
    font-size: 2vh;
  }
}

@media (max-width: 900px) {
  .Project_62-Project-Page-Cont .credits-cont {
    flex-direction: column;
    align-items: center;
  }
  .Project_62-Project-Page-Cont .credits-cont .cridits-title {
    height: auto;

    display: flex;
    justify-content: center;
    width: 100%;
  }
  .Project_62-Project-Page-Cont .credits-cont .description {
    height: 80%;
    margin-top: 10vh;
    width: 100%;
  }
  .Project_62-Project-Page-Cont
    .credits-cont
    .description
    .description-row
    span {
    font-size: 3vh;
  }
  .Project_62-Project-Page-Cont .credits-cont .cridits-title span {
    position: relative;
    font-size: 5vh;
  }
  .Project_62-Project-Page-Cont .hero-section .icons a {
    width: 6vh;
    margin: 0 1vh;
  }
}

@media (max-width: 770px) {
  .Project_62-Project-Page-Cont .hero-section .icons {
    width: 16vh;
  }
}
@media (max-width: 600px) {
  .Project_62-Project-Page-Cont .hero-section .icons {
    width: 15vh;
    top: 6vh;
    right: 3vh;
  }
  .Project_62-Project-Page-Cont
    .credits-cont
    .description
    .description-row
    span {
    font-size: 2vh;
  }
  .Project_62-Project-Page-Cont
    .credits-cont
    .description
    .description-row{
      height: 5vh;
    }
    .Project_62-Project-Page-Cont
    .credits-cont{
      width: 100%;
    }
}
@media (max-width: 420px) {
  .Project_62-Project-Page-Cont {
    width: 110%;
  }
  .Project_62-Project-Page-Cont
    .credits-cont
    .description
    .description-row
    span {
    font-size: 2vh;
  }
  .Project_62-Project-Page-Cont
    .credits-cont
    .description
    .description-row{
      height: 5vh;
    }
}


.Project_63-Project-Page-Cont {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-x: hidden;
}

.Project_63-Project-Page-Cont .hero-section {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Project_63-Project-Page-Cont .hero-section .icons {
  position: absolute;
  width: 10vw;
  z-index: 999;
  display: flex;
  right: 0;
  top: 3vw;
}

.Project_63-Project-Page-Cont .images-cont .icons a {
  width: 2vw;
  margin: 0 0.6vw;
}

.Project_63-Project-Page-Cont .images-cont .icons a svg {
  width: 100%;
}

.Project_63-Project-Page-Cont .hero-section .hero-video {
  width: 75vw !important;
  height: 42.2vw !important;
}

.Project_63-Project-Page-Cont .images-cont {
  width: 100%;
  height: auto;
  margin-top: 8vw;
}

.Project_63-Project-Page-Cont .images-cont .image-row {
  width: 100%;
  height: 100vh;
  display: flex;
}

.Project_63-Project-Page-Cont .images-cont .image-row .image {
  height: 100%;
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Project_63-Project-Page-Cont .images-cont .image-row .image img {
  width: 35vw;
}

.Project_63-Project-Page-Cont .images-cont .image-row .no-image {
  height: 100%;
  width: 50%;
}

.Project_63-Project-Page-Cont .credits-cont {
  width: 90%;
  height: 80vh;
  margin-top: 5vw;
  display: flex;
  border-top: 1px solid gray;
}

.Project_63-Project-Page-Cont .credits-cont .cridits-title {
  height: 100%;
  width: 50%;
  position: relative;
}

.Project_63-Project-Page-Cont .credits-cont .cridits-title span {
  font-family: "Cormorant Garamond", sans-serif;
  font-size: 2vw;
  text-transform: uppercase;
  letter-spacing: 1vw;
  position: absolute;
  top: 3vw;
}
.Project_63-Project-Page-Cont .credits-cont .description {
  height: 100%;
  width: 50%;
  padding-top: 1vw;
  display: flex;
  flex-direction: column;
}
.Project_63-Project-Page-Cont .credits-cont .description .description-row {
  width: 100%;
  height: 6vw;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid gray;
}

.Project_63-Project-Page-Cont .credits-cont .description .description-row span {
  font-family: "Cormorant Garamond", sans-serif;
  font-size: 1.5vw;
  text-transform: uppercase;
  letter-spacing: 3px;
}

.Project_63-Project-Page-Cont
  .credits-cont
  .description
  .description-row
  #large-title {
  font-size: 1.2vw;
}

@media (max-width: 1366px) {
  .Project_63-Project-Page-Cont .hero-section .hero-video {
    width: 127.5vh !important;
    height: 71.74vh !important;
  }
  .Project_63-Project-Page-Cont .images-cont .image-row .no-image {
    display: none;
  }
  .Project_63-Project-Page-Cont .images-cont .image-row .image {
    width: 100%;
  }
  .Project_63-Project-Page-Cont .images-cont .image-row .image img {
    width: 65vh;
  }
  .Project_63-Project-Page-Cont
    .credits-cont
    .description
    .description-row
    span {
    font-size: 2vh;
  }
}

@media (max-width: 900px) {
  .Project_63-Project-Page-Cont .credits-cont {
    flex-direction: column;
    align-items: center;
  }
  .Project_63-Project-Page-Cont .credits-cont .cridits-title {
    height: auto;

    display: flex;
    justify-content: center;
    width: 100%;
  }
  .Project_63-Project-Page-Cont .credits-cont .description {
    height: 80%;
    margin-top: 10vh;
    width: 100%;
  }
  .Project_63-Project-Page-Cont
    .credits-cont
    .description
    .description-row
    span {
    font-size: 3vh;
  }
  .Project_63-Project-Page-Cont .credits-cont .cridits-title span {
    position: relative;
    font-size: 5vh;
  }
  .Project_63-Project-Page-Cont .hero-section .icons a {
    width: 6vh;
    margin: 0 1vh;
  }
}

@media (max-width: 770px) {
  .Project_63-Project-Page-Cont .hero-section .icons {
    width: 16vh;
  }
}
@media (max-width: 600px) {
  .Project_63-Project-Page-Cont .hero-section .icons {
    width: 15vh;
    top: 6vh;
    right: 3vh;
  }
  .Project_63-Project-Page-Cont
    .credits-cont
    .description
    .description-row
    span {
    font-size: 2vh;
  }
  .Project_63-Project-Page-Cont .credits-cont .description .description-row {
    height: 5vh;
  }
  .Project_63-Project-Page-Cont .credits-cont {
    width: 100%;
  }
}
@media (max-width: 420px) {
  .Project_63-Project-Page-Cont {
    width: 110%;
  }
  .Project_63-Project-Page-Cont
    .credits-cont
    .description
    .description-row
    span {
    font-size: 2vh;
  }
  .Project_63-Project-Page-Cont .credits-cont .description .description-row {
    height: 5vh;
  }
  .Project_63-Project-Page-Cont
    .credits-cont
    .description
    .description-row
    #large-title {
    font-size: 1.2vh;
  }
}

.Project_71-Project-Page-Cont {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-x: hidden;
}

.Project_71-Project-Page-Cont .hero-section {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Project_71-Project-Page-Cont .hero-section .icons {
  position: absolute;
  width: 10vw;
  z-index: 999;
  display: flex;
  right: 0;
  top: 3vw;
}

.Project_71-Project-Page-Cont .images-cont .icons a {
  width: 2vw;
  margin: 0 0.6vw;
}

.Project_71-Project-Page-Cont .images-cont .icons a svg {
  width: 100%;
}

.Project_71-Project-Page-Cont .hero-section .hero-video {
  width: 75vw !important;
  height: 42.2vw !important;
}

.Project_71-Project-Page-Cont .images-cont {
  width: 100%;
  height: auto;
  margin-top: 8vw;
}

.Project_71-Project-Page-Cont .images-cont .image-row {
  width: 100%;
  height: 100vh;
  display: flex;
}

.Project_71-Project-Page-Cont .images-cont .image-row .image {
  height: 100%;
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Project_71-Project-Page-Cont .images-cont .image-row .image img {
  width: 35vw;
}

.Project_71-Project-Page-Cont .images-cont .image-row .no-image {
  height: 100%;
  width: 50%;
}

.Project_71-Project-Page-Cont .credits-cont {
  width: 90%;
  height: 80vh;
  margin-top: 5vw;
  display: flex;
  border-top: 1px solid gray;
}

.Project_71-Project-Page-Cont .credits-cont .cridits-title {
  height: 100%;
  width: 50%;
  position: relative;
}

.Project_71-Project-Page-Cont .credits-cont .cridits-title span {
  font-family: "Cormorant Garamond", sans-serif;
  font-size: 2vw;
  text-transform: uppercase;
  letter-spacing: 1vw;
  position: absolute;
  top: 3vw;
}
.Project_71-Project-Page-Cont .credits-cont .description {
  height: 100%;
  width: 50%;
  padding-top: 1vw;
  display: flex;
  flex-direction: column;
}
.Project_71-Project-Page-Cont .credits-cont .description .description-row {
  width: 100%;
  height: 6vw;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid gray;
}

.Project_71-Project-Page-Cont .credits-cont .description .description-row span {
  font-family: "Cormorant Garamond", sans-serif;
  font-size: 1.5vw;
  text-transform: uppercase;
  letter-spacing: 3px;
}

.Project_71-Project-Page-Cont
  .credits-cont
  .description
  .description-row
  #large-title {
  font-size: 1.2vw;
}
@media (max-width: 1366px) {
  .Project_71-Project-Page-Cont .hero-section .hero-video {
    width: 127.5vh !important;
    height: 71.74vh !important;
  }
  .Project_71-Project-Page-Cont .images-cont .image-row .no-image {
    display: none;
  }
  .Project_71-Project-Page-Cont .images-cont .image-row .image {
    width: 100%;
  }
  .Project_71-Project-Page-Cont .images-cont .image-row .image img {
    width: 65vh;
  }
  .Project_71-Project-Page-Cont
    .credits-cont
    .description
    .description-row
    span {
    font-size: 2vh;
  }
}

@media (max-width: 900px) {
  .Project_71-Project-Page-Cont .credits-cont {
    flex-direction: column;
    align-items: center;
  }
  .Project_71-Project-Page-Cont .credits-cont .cridits-title {
    height: auto;

    display: flex;
    justify-content: center;
    width: 100%;
  }
  .Project_71-Project-Page-Cont .credits-cont .description {
    height: 80%;
    margin-top: 10vh;
    width: 100%;
  }
  .Project_71-Project-Page-Cont
    .credits-cont
    .description
    .description-row
    span {
    font-size: 3vh;
  }
  .Project_71-Project-Page-Cont .credits-cont .cridits-title span {
    position: relative;
    font-size: 5vh;
  }
  .Project_71-Project-Page-Cont .hero-section .icons a {
    width: 6vh;
    margin: 0 1vh;
  }
}

@media (max-width: 770px) {
  .Project_71-Project-Page-Cont .hero-section .icons {
    width: 16vh;
  }
}
@media (max-width: 600px) {
  .Project_71-Project-Page-Cont .hero-section .icons {
    width: 15vh;
    top: 6vh;
    right: 3vh;
  }
  .Project_71-Project-Page-Cont
    .credits-cont
    .description
    .description-row
    span {
    font-size: 2vh;
  }
  .Project_71-Project-Page-Cont .credits-cont .description .description-row {
    height: 5vh;
  }
  .Project_71-Project-Page-Cont .credits-cont {
    width: 100%;
  }
  .Project_71-Project-Page-Cont
    .credits-cont
    .description
    .description-row
    #large-title {
    font-size: 2vh;
  }
}
@media (max-width: 420px) {
  .Project_71-Project-Page-Cont {
    width: 110%;
  }
  .Project_71-Project-Page-Cont
    .credits-cont
    .description
    .description-row
    span {
    font-size: 2vh;
  }
  .Project_71-Project-Page-Cont .credits-cont .description .description-row {
    height: 5vh;
  }
  .Project_71-Project-Page-Cont
    .credits-cont
    .description
    .description-row
    #large-title {
    font-size: 2vh;
  }
}

.Project_72-Project-Page-Cont {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-x: hidden;
}

.Project_72-Project-Page-Cont .hero-section {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Project_72-Project-Page-Cont .hero-section .icons {
  position: absolute;
  width: 10vw;
  z-index: 999;
  display: flex;
  right: 0;
  top: 3vw;
}

.Project_72-Project-Page-Cont .hero-section .icons a {
  width: 2vw;
  margin: 0 .6vw;
}

.Project_72-Project-Page-Cont .hero-section .icons a svg {
  width: 100%;
}

.Project_72-Project-Page-Cont .hero-section .hero-video {
  width: 75vw !important;
  height: 42.2vw !important;
}

.Project_72-Project-Page-Cont .images-cont {
  width: 100%;
  height: auto;
  margin-top: 8vw;
}

.Project_72-Project-Page-Cont .images-cont .image-row {
  width: 100%;
  height: 100vh;
  display: flex;
}

.Project_72-Project-Page-Cont .images-cont .image-row .image {
  height: 100%;
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Project_72-Project-Page-Cont .images-cont .image-row .image img {
  width: 35vw;
}

.Project_72-Project-Page-Cont .images-cont .image-row .no-image {
  height: 100%;
  width: 50%;
}

.Project_72-Project-Page-Cont .credits-cont {
  width: 90%;
  height: 80vh;
  margin-top: 5vw;
  display: flex;
  border-top: 1px solid gray;
}

.Project_72-Project-Page-Cont .credits-cont .cridits-title {
  height: 100%;
  width: 50%;
  position: relative;
}

.Project_72-Project-Page-Cont .credits-cont .cridits-title span {
  font-family: "Cormorant Garamond", sans-serif;
  font-size: 2vw;
  text-transform: uppercase;
  letter-spacing: 1vw;
  position: absolute;
  top: 3vw;
}
.Project_72-Project-Page-Cont .credits-cont .description {
  height: 100%;
  width: 50%;
  padding-top: 1vw;
  display: flex;
  flex-direction: column;
}
.Project_72-Project-Page-Cont .credits-cont .description .description-row {
  width: 100%;
  height: 6vw;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid gray;
}

.Project_72-Project-Page-Cont .credits-cont .description .description-row span{
  font-family: "Cormorant Garamond", sans-serif;
  font-size: 1.5vw;
  text-transform: uppercase;
  letter-spacing: 3px;
}

@media (max-width: 1366px) {
  .Project_72-Project-Page-Cont .hero-section .hero-video {
    width: 127.5vh !important;
    height: 71.74vh !important;
  }
  .Project_72-Project-Page-Cont .images-cont .image-row .no-image {
    display: none;
  }
  .Project_72-Project-Page-Cont .images-cont .image-row .image {
    width: 100%;
  }
  .Project_72-Project-Page-Cont .images-cont .image-row .image img {
    width: 65vh;
  }
  .Project_72-Project-Page-Cont
    .credits-cont
    .description
    .description-row
    span {
    font-size: 2vh;
  }
}

@media (max-width: 900px) {
  .Project_72-Project-Page-Cont .credits-cont {
    flex-direction: column;
    align-items: center;
  }
  .Project_72-Project-Page-Cont .credits-cont .cridits-title {
    height: auto;

    display: flex;
    justify-content: center;
    width: 100%;
  }
  .Project_72-Project-Page-Cont .credits-cont .description {
    height: 80%;
    margin-top: 10vh;
    width: 100%;
  }
  .Project_72-Project-Page-Cont
    .credits-cont
    .description
    .description-row
    span {
    font-size: 3vh;
  }
  .Project_72-Project-Page-Cont .credits-cont .cridits-title span {
    position: relative;
    font-size: 5vh;
  }
  .Project_72-Project-Page-Cont .hero-section .icons a {
    width: 6vh;
    margin: 0 1vh;
  }
}

@media (max-width: 770px) {
  .Project_72-Project-Page-Cont .hero-section .icons {
    width: 16vh;
  }
}
@media (max-width: 600px) {
  .Project_72-Project-Page-Cont .hero-section .icons {
    width: 15vh;
    top: 6vh;
    right: 3vh;
  }
  .Project_72-Project-Page-Cont
    .credits-cont
    .description
    .description-row
    span {
    font-size: 2vh;
  }
  .Project_72-Project-Page-Cont
    .credits-cont
    .description
    .description-row{
      height: 5vh;
    }
    .Project_72-Project-Page-Cont
    .credits-cont{
      width: 100%;
    }
}
@media (max-width: 420px) {
  .Project_72-Project-Page-Cont {
    width: 110%;
  }
  .Project_72-Project-Page-Cont
    .credits-cont
    .description
    .description-row
    span {
    font-size: 2vh;
  }
  .Project_72-Project-Page-Cont
    .credits-cont
    .description
    .description-row{
      height: 5vh;
    }
}


.Project_73-Project-Page-Cont {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-x: hidden;
}

.Project_73-Project-Page-Cont .hero-section {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Project_73-Project-Page-Cont .hero-section .icons {
  position: absolute;
  width: 10vw;
  z-index: 999;
  display: flex;
  right: 0;
  top: 3vw;
}

.Project_73-Project-Page-Cont .hero-section .icons a {
  width: 2vw;
  margin: 0 .6vw;
}

.Project_73-Project-Page-Cont .hero-section .icons a svg {
  width: 100%;
}

.Project_73-Project-Page-Cont .hero-section .hero-video {
  width: 75vw !important;
  height: 42.2vw !important;
}

.Project_73-Project-Page-Cont .images-cont {
  width: 100%;
  height: auto;
  margin-top: 8vw;
}

.Project_73-Project-Page-Cont .images-cont .image-row {
  width: 100%;
  height: 100vh;
  display: flex;
}

.Project_73-Project-Page-Cont .images-cont .image-row .image {
  height: 100%;
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Project_73-Project-Page-Cont .images-cont .image-row .image img {
  width: 35vw;
}

.Project_73-Project-Page-Cont .images-cont .image-row .no-image {
  height: 100%;
  width: 50%;
}

.Project_73-Project-Page-Cont .credits-cont {
  width: 90%;
  height: 80vh;
  margin-top: 5vw;
  display: flex;
  border-top: 1px solid gray;
}

.Project_73-Project-Page-Cont .credits-cont .cridits-title {
  height: 100%;
  width: 50%;
  position: relative;
}

.Project_73-Project-Page-Cont .credits-cont .cridits-title span {
  font-family: "Cormorant Garamond", sans-serif;
  font-size: 2vw;
  text-transform: uppercase;
  letter-spacing: 1vw;
  position: absolute;
  top: 3vw;
}
.Project_73-Project-Page-Cont .credits-cont .description {
  height: 100%;
  width: 50%;
  padding-top: 1vw;
  display: flex;
  flex-direction: column;
}
.Project_73-Project-Page-Cont .credits-cont .description .description-row {
  width: 100%;
  height: 6vw;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid gray;
}

.Project_73-Project-Page-Cont .credits-cont .description .description-row span{
  font-family: "Cormorant Garamond", sans-serif;
  font-size: 1.5vw;
  text-transform: uppercase;
  letter-spacing: 3px;
}

@media (max-width: 1366px) {
  .Project_73-Project-Page-Cont .hero-section .hero-video {
    width: 127.5vh !important;
    height: 71.74vh !important;
  }
  .Project_73-Project-Page-Cont .images-cont .image-row .no-image {
    display: none;
  }
  .Project_73-Project-Page-Cont .images-cont .image-row .image {
    width: 100%;
  }
  .Project_73-Project-Page-Cont .images-cont .image-row .image img {
    width: 65vh;
  }
  .Project_73-Project-Page-Cont
    .credits-cont
    .description
    .description-row
    span {
    font-size: 2vh;
  }
}

@media (max-width: 900px) {
  .Project_73-Project-Page-Cont .credits-cont {
    flex-direction: column;
    align-items: center;
  }
  .Project_73-Project-Page-Cont .credits-cont .cridits-title {
    height: auto;

    display: flex;
    justify-content: center;
    width: 100%;
  }
  .Project_73-Project-Page-Cont .credits-cont .description {
    height: 80%;
    margin-top: 10vh;
    width: 100%;
  }
  .Project_73-Project-Page-Cont
    .credits-cont
    .description
    .description-row
    span {
    font-size: 3vh;
  }
  .Project_73-Project-Page-Cont .credits-cont .cridits-title span {
    position: relative;
    font-size: 5vh;
  }
  .Project_73-Project-Page-Cont .hero-section .icons a {
    width: 6vh;
    margin: 0 1vh;
  }
}

@media (max-width: 770px) {
  .Project_73-Project-Page-Cont .hero-section .icons {
    width: 16vh;
  }
}
@media (max-width: 600px) {
  .Project_73-Project-Page-Cont .hero-section .icons {
    width: 15vh;
    top: 6vh;
    right: 3vh;
  }
  .Project_73-Project-Page-Cont
    .credits-cont
    .description
    .description-row
    span {
    font-size: 2vh;
  }
  .Project_73-Project-Page-Cont
    .credits-cont
    .description
    .description-row{
      height: 5vh;
    }
    .Project_73-Project-Page-Cont
    .credits-cont{
      width: 100%;
    }
}
@media (max-width: 420px) {
  .Project_73-Project-Page-Cont {
    width: 110%;
  }
  .Project_73-Project-Page-Cont
    .credits-cont
    .description
    .description-row
    span {
    font-size: 2vh;
  }
  .Project_73-Project-Page-Cont
    .credits-cont
    .description
    .description-row{
      height: 5vh;
    }
}


.Project_81-Project-Page-Cont {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-x: hidden;
}

.Project_81-Project-Page-Cont .hero-section {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Project_81-Project-Page-Cont .hero-section .icons {
  position: absolute;
  width: 10vw;
  z-index: 999;
  display: flex;
  right: 0;
  top: 3vw;
}

.Project_81-Project-Page-Cont .hero-section .icons a {
  width: 2vw;
  margin: 0 .6vw;
}

.Project_81-Project-Page-Cont .hero-section .icons a svg {
  width: 100%;
}

.Project_81-Project-Page-Cont .hero-section .hero-video {
  width: 75vw !important;
  height: 42.2vw !important;
}

.Project_81-Project-Page-Cont .images-cont {
  width: 100%;
  height: auto;
  margin-top: 8vw;
}

.Project_81-Project-Page-Cont .images-cont .image-row {
  width: 100%;
  height: 100vh;
  display: flex;
}

.Project_81-Project-Page-Cont .images-cont .image-row .image {
  height: 100%;
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Project_81-Project-Page-Cont .images-cont .image-row .image img {
  width: 35vw;
}

.Project_81-Project-Page-Cont .images-cont .image-row .no-image {
  height: 100%;
  width: 50%;
}

.Project_81-Project-Page-Cont .credits-cont {
  width: 90%;
  height: 80vh;
  margin-top: 5vw;
  display: flex;
  border-top: 1px solid gray;
}

.Project_81-Project-Page-Cont .credits-cont .cridits-title {
  height: 100%;
  width: 50%;
  position: relative;
}

.Project_81-Project-Page-Cont .credits-cont .cridits-title span {
  font-family: "Cormorant Garamond", sans-serif;
  font-size: 2vw;
  text-transform: uppercase;
  letter-spacing: 1vw;
  position: absolute;
  top: 3vw;
}
.Project_81-Project-Page-Cont .credits-cont .description {
  height: 100%;
  width: 50%;
  padding-top: 1vw;
  display: flex;
  flex-direction: column;
}
.Project_81-Project-Page-Cont .credits-cont .description .description-row {
  width: 100%;
  height: 6vw;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid gray;
}

.Project_81-Project-Page-Cont .credits-cont .description .description-row span{
  font-family: "Cormorant Garamond", sans-serif;
  font-size: 1.5vw;
  text-transform: uppercase;
  letter-spacing: 3px;
}

@media (max-width: 1366px) {
  .Project_81-Project-Page-Cont .hero-section .hero-video {
    width: 127.5vh !important;
    height: 71.74vh !important;
  }
  .Project_81-Project-Page-Cont .images-cont .image-row .no-image {
    display: none;
  }
  .Project_81-Project-Page-Cont .images-cont .image-row .image {
    width: 100%;
  }
  .Project_81-Project-Page-Cont .images-cont .image-row .image img {
    width: 65vh;
  }
  .Project_81-Project-Page-Cont
    .credits-cont
    .description
    .description-row
    span {
    font-size: 2vh;
  }
}

@media (max-width: 900px) {
  .Project_81-Project-Page-Cont .credits-cont {
    flex-direction: column;
    align-items: center;
  }
  .Project_81-Project-Page-Cont .credits-cont .cridits-title {
    height: auto;

    display: flex;
    justify-content: center;
    width: 100%;
  }
  .Project_81-Project-Page-Cont .credits-cont .description {
    height: 80%;
    margin-top: 10vh;
    width: 100%;
  }
  .Project_81-Project-Page-Cont
    .credits-cont
    .description
    .description-row
    span {
    font-size: 3vh;
  }
  .Project_81-Project-Page-Cont .credits-cont .cridits-title span {
    position: relative;
    font-size: 5vh;
  }
  .Project_81-Project-Page-Cont .hero-section .icons a {
    width: 6vh;
    margin: 0 1vh;
  }
}

@media (max-width: 770px) {
  .Project_81-Project-Page-Cont .hero-section .icons {
    width: 16vh;
  }
}
@media (max-width: 600px) {
  .Project_81-Project-Page-Cont .hero-section .icons {
    width: 15vh;
    top: 6vh;
    right: 3vh;
  }
  .Project_81-Project-Page-Cont
    .credits-cont
    .description
    .description-row
    span {
    font-size: 2vh;
  }
  .Project_81-Project-Page-Cont
    .credits-cont
    .description
    .description-row{
      height: 5vh;
    }
    .Project_81-Project-Page-Cont
    .credits-cont{
      width: 100%;
    }
}
@media (max-width: 420px) {
  .Project_81-Project-Page-Cont {
    width: 110%;
  }
  .Project_81-Project-Page-Cont
    .credits-cont
    .description
    .description-row
    span {
    font-size: 2vh;
  }
  .Project_81-Project-Page-Cont
    .credits-cont
    .description
    .description-row{
      height: 5vh;
    }
}


.Project_82-Project-Page-Cont {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-x: hidden;
}

.Project_82-Project-Page-Cont .hero-section {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Project_82-Project-Page-Cont .hero-section .icons {
  position: absolute;
  width: 10vw;
  z-index: 999;
  display: flex;
  right: 0;
  top: 3vw;
}

.Project_82-Project-Page-Cont .images-cont .icons a {
  width: 2vw;
  margin: 0 .6vw;
}

.Project_82-Project-Page-Cont .images-cont .icons a svg {
  width: 100%;
}

.Project_82-Project-Page-Cont .hero-section .hero-video {
  width: 75vw !important;
  height: 42.2vw !important;
}

.Project_82-Project-Page-Cont .images-cont {
  width: 100%;
  height: auto;
  margin-top: 8vw;
}

.Project_82-Project-Page-Cont .images-cont .image-row {
  width: 100%;
  height: 100vh;
  display: flex;
}

.Project_82-Project-Page-Cont .images-cont .image-row .image {
  height: 100%;
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Project_82-Project-Page-Cont .images-cont .image-row .image img {
  width: 35vw;
}

.Project_82-Project-Page-Cont .images-cont .image-row .no-image {
  height: 100%;
  width: 50%;
}

.Project_82-Project-Page-Cont .credits-cont {
  width: 90%;
  height: 80vh;
  margin-top: 5vw;
  display: flex;
  border-top: 1px solid gray;
}

.Project_82-Project-Page-Cont .credits-cont .cridits-title {
  height: 100%;
  width: 50%;
  position: relative;
}

.Project_82-Project-Page-Cont .credits-cont .cridits-title span {
  font-family: "Cormorant Garamond", sans-serif;
  font-size: 2vw;
  text-transform: uppercase;
  letter-spacing: 1vw;
  position: absolute;
  top: 3vw;
}
.Project_82-Project-Page-Cont .credits-cont .description {
  height: 100%;
  width: 50%;
  padding-top: 1vw;
  display: flex;
  flex-direction: column;
}
.Project_82-Project-Page-Cont .credits-cont .description .description-row {
  width: 100%;
  height: 6vw;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid gray;
}

.Project_82-Project-Page-Cont .credits-cont .description .description-row span{
  font-family: "Cormorant Garamond", sans-serif;
  font-size: 1.5vw;
  text-transform: uppercase;
  letter-spacing: 3px;
}

.Project_82-Project-Page-Cont .credits-cont .description .description-row #large-title{
  font-size: 1.1vw;
}
@media (max-width: 1366px) {
  .Project_82-Project-Page-Cont .hero-section .hero-video {
    width: 127.5vh !important;
    height: 71.74vh !important;
  }
  .Project_82-Project-Page-Cont .images-cont .image-row .no-image {
    display: none;
  }
  .Project_82-Project-Page-Cont .images-cont .image-row .image {
    width: 100%;
  }
  .Project_82-Project-Page-Cont .images-cont .image-row .image img {
    width: 65vh;
  }
  .Project_82-Project-Page-Cont
    .credits-cont
    .description
    .description-row
    span {
    font-size: 2vh;
  }
}

@media (max-width: 900px) {
  .Project_82-Project-Page-Cont .credits-cont {
    flex-direction: column;
    align-items: center;
  }
  .Project_82-Project-Page-Cont .credits-cont .cridits-title {
    height: auto;

    display: flex;
    justify-content: center;
    width: 100%;
  }
  .Project_82-Project-Page-Cont .credits-cont .description {
    height: 80%;
    margin-top: 10vh;
    width: 100%;
  }
  .Project_82-Project-Page-Cont
    .credits-cont
    .description
    .description-row
    span {
    font-size: 3vh;
  }
  .Project_82-Project-Page-Cont .credits-cont .cridits-title span {
    position: relative;
    font-size: 5vh;
  }
  .Project_82-Project-Page-Cont .hero-section .icons a {
    width: 6vh;
    margin: 0 1vh;
  }
}

@media (max-width: 770px) {
  .Project_82-Project-Page-Cont .hero-section .icons {
    width: 16vh;
  }
}
@media (max-width: 600px) {
  .Project_82-Project-Page-Cont .hero-section .icons {
    width: 15vh;
    top: 6vh;
    right: 3vh;
  }
  .Project_82-Project-Page-Cont
    .credits-cont
    .description
    .description-row
    span {
    font-size: 2vh;
  }
  .Project_82-Project-Page-Cont
    .credits-cont
    .description
    .description-row{
      height: 5vh;
    }
    .Project_82-Project-Page-Cont
    .credits-cont{
      width: 100%;
    }
}
@media (max-width: 420px) {
  .Project_82-Project-Page-Cont {
    width: 110%;
  }
  .Project_82-Project-Page-Cont
    .credits-cont
    .description
    .description-row
    span {
    font-size: 2vh;
  }
  .Project_82-Project-Page-Cont
    .credits-cont
    .description
    .description-row{
      height: 5vh;
    }
    .Project_82-Project-Page-Cont .credits-cont .description .description-row #large-title{
  font-size: 1.3vh;
}
}
.Project_83-Project-Page-Cont {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-x: hidden;
}

.Project_83-Project-Page-Cont .hero-section {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Project_83-Project-Page-Cont .hero-section .icons {
  position: absolute;
  width: 10vw;
  z-index: 999;
  display: flex;
  right: 0;
  top: 3vw;
}

.Project_83-Project-Page-Cont .hero-section .icons a {
  width: 2vw;
  margin: 0 .6vw;
}

.Project_83-Project-Page-Cont .hero-section .icons a svg {
  width: 100%;
}

.Project_83-Project-Page-Cont .hero-section .hero-video {
  width: 75vw !important;
  height: 42.2vw !important;
}

.Project_83-Project-Page-Cont .images-cont {
  width: 100%;
  height: auto;
  margin-top: 8vw;
}

.Project_83-Project-Page-Cont .images-cont .image-row {
  width: 100%;
  height: 100vh;
  display: flex;
}

.Project_83-Project-Page-Cont .images-cont .image-row .image {
  height: 100%;
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Project_83-Project-Page-Cont .images-cont .image-row .image img {
  width: 35vw;
}

.Project_83-Project-Page-Cont .images-cont .image-row .no-image {
  height: 100%;
  width: 50%;
}

.Project_83-Project-Page-Cont .credits-cont {
  width: 90%;
  height: 80vh;
  margin-top: 5vw;
  display: flex;
  border-top: 1px solid gray;
}

.Project_83-Project-Page-Cont .credits-cont .cridits-title {
  height: 100%;
  width: 50%;
  position: relative;
}

.Project_83-Project-Page-Cont .credits-cont .cridits-title span {
  font-family: "Cormorant Garamond", sans-serif;
  font-size: 2vw;
  text-transform: uppercase;
  letter-spacing: 1vw;
  position: absolute;
  top: 3vw;
}
.Project_83-Project-Page-Cont .credits-cont .description {
  height: 100%;
  width: 50%;
  padding-top: 1vw;
  display: flex;
  flex-direction: column;
}
.Project_83-Project-Page-Cont .credits-cont .description .description-row {
  width: 100%;
  height: 6vw;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid gray;
}

.Project_83-Project-Page-Cont .credits-cont .description .description-row span{
  font-family: "Cormorant Garamond", sans-serif;
  font-size: 1.5vw;
  text-transform: uppercase;
  letter-spacing: 3px;
}

.Project_83-Project-Page-Cont .credits-cont .description .description-row #large-title{
    font-size: 1.2vw;
}

@media (max-width: 1366px) {
  .Project_83-Project-Page-Cont .hero-section .hero-video {
    width: 127.5vh !important;
    height: 71.74vh !important;
  }
  .Project_83-Project-Page-Cont .images-cont .image-row .no-image {
    display: none;
  }
  .Project_83-Project-Page-Cont .images-cont .image-row .image {
    width: 100%;
  }
  .Project_83-Project-Page-Cont .images-cont .image-row .image img {
    width: 65vh;
  }
  .Project_83-Project-Page-Cont
    .credits-cont
    .description
    .description-row
    span {
    font-size: 2vh;
  }
}

@media (max-width: 900px) {
  .Project_83-Project-Page-Cont .credits-cont {
    flex-direction: column;
    align-items: center;
  }
  .Project_83-Project-Page-Cont .credits-cont .cridits-title {
    height: auto;

    display: flex;
    justify-content: center;
    width: 100%;
  }
  .Project_83-Project-Page-Cont .credits-cont .description {
    height: 80%;
    margin-top: 10vh;
    width: 100%;
  }
  .Project_83-Project-Page-Cont
    .credits-cont
    .description
    .description-row
    span {
    font-size: 3vh;
  }
  .Project_83-Project-Page-Cont .credits-cont .cridits-title span {
    position: relative;
    font-size: 5vh;
  }
  .Project_83-Project-Page-Cont .hero-section .icons a {
    width: 6vh;
    margin: 0 1vh;
  }
}

@media (max-width: 770px) {
  .Project_83-Project-Page-Cont .hero-section .icons {
    width: 16vh;
  }
}
@media (max-width: 600px) {
  .Project_83-Project-Page-Cont .hero-section .icons {
    width: 15vh;
    top: 6vh;
    right: 3vh;
  }
  .Project_83-Project-Page-Cont
    .credits-cont
    .description
    .description-row
    span {
    font-size: 2vh;
  }
  .Project_83-Project-Page-Cont
    .credits-cont
    .description
    .description-row{
      height: 5vh;
    }
    .Project_83-Project-Page-Cont
    .credits-cont{
      width: 100%;
    }
}
@media (max-width: 420px) {
  .Project_83-Project-Page-Cont {
    width: 110%;
  }
  .Project_11-Project-Page-Cont
    .credits-cont
    .description
    .description-row
    span {
    font-size: 2vh;
  }
  .Project_83-Project-Page-Cont
    .credits-cont
    .description
    .description-row{
      height: 5vh;
    }
    .Project_83-Project-Page-Cont .credits-cont .description .description-row #large-title{
    font-size: 1.5vh;
}
}


